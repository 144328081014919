.overview-card {
    @extend .ani-links;
    display: block;
    color: inherit;
    // box-shadow: $shadow10;
    border: $border-thin;
    border-radius: $rounded-small;

    &:hover {
        color: inherit;
        box-shadow: $shadow20;
    }

    &:active {
        box-shadow: none;
    }

    &__wrapper {
        padding: tr(24) tr(16);
        display: flex;
    }

    &__icon {
        font-size: tr(64);
        color: $color-primary;
        margin-right: tr(16);
    }

    &__des {
        opacity: .8;

        &__title {
            font-size: tr(16);
            margin-bottom: tr(4);
        }

        &__val {
            font-size: tr(20);
            font-weight: $fw-bold;
        }
    }

    @media (max-width:575.98px) {

        &__wrapper {
            padding: tr(16);
            display: flex;
        }
        
        &__icon {
            font-size: tr(56);
            margin-right: tr(12);
        }

        &__des {
            transform: translateY(tr(5));
    
            &__title {
                font-size: tr(12);
            }
    
            &__val {
                font-size: tr(16);
            }
        }
    }
}