.program-head {
    padding: tr(40) 0 0;

    > * {
        margin-bottom: tr(-96);
    }

    ._title {
        margin-bottom: tr(24);

        @media (max-width: 1199.98px) {
            margin-bottom: 1rem;
        }
    }

    &__img {
        // height: tr(398);
        position: relative;
        border-radius: $rounded-xlarge;
        width: 100%;
        height: tr(395);
        // aspect-ratio: 1/1;
        overflow: hidden;
        // border: 1px solid $color-gray;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: right;

            @media (max-width: 767.98px) {
                object-fit: cover;
                object-position: center;
            }
        }

        &__overlay {
            display: flex;
            align-items: flex-start;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            padding: 2.5rem;
            background: rgb(255,255,255);
            background: linear-gradient(90deg, #eef2f3 52%, rgba(#eef2f3, 0.7) 80%, rgba(255, 255, 255, 0) 100%);

            ._title {
                max-width: 50%;
                margin-bottom: 0;

                .badges {
                    padding: 0.5rem 1rem;
                    font-size: 0.875rem;
                    // position: absolute;
                    // top: 2rem;
                }

                .h4 {
                    background: linear-gradient(90deg, #389ED9 1.35%, #B1CF1F 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    color: $color-accent;
                    line-height: 1.3;
                }
            }
        }

        @media (max-width: 767.98px) {
            border-radius: $rounded-large;
            // height: tr(302);
            height: auto;
            aspect-ratio: 1/1;

        }

        @media (max-width: 575.98px) {
            // height: 56vw;
        }
    }
}