// Label Category
.label-category {
    color: $color-secondary;
    margin-bottom: tr(4);
    line-height: 2;

    @media (max-width:991.98px) {
        font-size: tr(10);
    }
}

// Rupiah
.rp {
    
    &::before {
        display: inline-block;
        content: 'Rp';
        margin-right: tr(4);
    }
}

// No Select
.noselect {
    -webkit-touch-callout: none; // iOS Safari
      -webkit-user-select: none; // Safari
       -khtml-user-select: none; // Konqueror HTML
         -moz-user-select: none; // Old versions of Firefox
          -ms-user-select: none; // Internet Explorer/Edge
              user-select: none; // Non-prefixed version, currently supported by Chrome, Opera and Firefox
  }

  .opc8 {
      opacity: .8;
  }

  .avatar {

    border-radius: $rounded-circle;
    object-fit: cover;
    background-color: $color-primary;

    &__xs {
        width: tr(24);
        height: tr(24);
    }
    &__sm-s {
        width: tr(40);
        height: tr(40);
    }
    &__sm-l {
        width: tr(45);
        height: tr(45);
    }
    &__sm {
        width: tr(48);
        height: tr(48);
    }
    &__md-s {
        width: tr(56);
        height: tr(56);
    }
    &__md {
        width: tr(64);
        height: tr(64);
    }
    &__lg {
        width: tr(80);
        height: tr(80);
    }
    &__xl {
        width: tr(144);
        height: tr(144);

        @media (max-width: 767.98px) {
            width: tr(64);
            height: tr(64);
        }
    }

    &__xxl {
        width: tr(200);
        height: tr(200);

        @media (max-width: 1199.98px) {
            width: tr(148);
            height: tr(148);
        }
    }
  }

.ani-links {

    @media (min-width:992px) {
        transition: all .2s;
    
        &:hover {
            opacity: 1;
            transform: translateY(tr(-2));
        }
    
        &:active {
            transform: translateY(0);
        }
    }
}

.empty-state {
    display: flex;
    align-items: center;
    padding: tr(16) tr(26);
    border-radius: $rounded-large;
    border: tr(1) solid $color-gray;

    > div {
        &:first-child {
            display: flex;
            align-items: center;

            @media (max-width: 767.98px) {
                flex-direction: column;
                text-align: center;
            }
        }

        &:last-child {
            @media (max-width: 767.98px) {
                margin-top: tr(24);
            }
        }
    }

    .pattern-icon {
        height: tr(60);
        width: tr(60);

        i {
            font-size: tr(36);
        }
    }

    .h5 {
        flex: 1;
        color: $color-base2;

        @media (min-width: 768px) {
            margin-left: tr(16);
        }

        @media (max-width: 767.98px) {
            margin-top: tr(24);
            font-size: tr(18);
        }
    }

    .link-btn {
        width: tr(180);
    }

    @media (max-width: 767.98px) {
        flex-direction: column;
    }
}

.change-img {
    display: flex;
    align-items: center;

    .avatar {
        margin-right: tr(24);
    }

    ._right {
        flex: 1;
        display: flex;
        flex-direction: column;


        .lobel {
            margin-top: tr(12);
            padding-left: tr(8);
        }
    }
}

.pattern-icon {
    width: tr(120);
    height: tr(120);
    font-size: tr(64);
    position: relative;
    background: linear-gradient(61.72deg, #389ED9 -80.49%, #B1CF1F 102.45%);
    color: white;
    border-radius: $rounded-circle;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &::before {
        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top tr(22);
        background-image: url('../img/pattern-icon.svg');
    }

    > * {
        position: relative;
        z-index: 1;
    }
}