.header-home {
    position: relative;

    .hero-slider {

        .swiper-wrapper {
            @media (max-width: 991.98px) {
                padding-bottom: tr(16);
            }

            @media (max-width: 767.98px) {
                padding-bottom: tr(72);
            }

            .swiper-slide {
                cursor: grab;
                position: relative;
                height: calc(100vh - tr(64));

                @media (max-width: 991.98px) {
                    height: 58vw;
                }

                &.no-btn {
                    @media (max-width: 767.98px) {
                        height: calc(58vw + 58px);
                        margin-bottom: -58px;
                    }
                }

                // @media (max-width: 767.98px) {
                //     height: 56vw;
                // }

                video {
                    width: 100%;
                    height: 100%;
                    // -o-object-fit: cover;
                    object-fit: cover;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .hero-slider__content {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;

                    .container {
                        height: 100%;
                        display: grid;
                        align-items: center;
                    }

                    ._box {
                        max-width: tr(600);

                        @media (max-width: 1199.98px) {
                            max-width: 36vw;
                        }
                        
                        @media (max-width: 787.98px) {
                            max-width: 46vw;
                        }

                        .h3 {
                            margin-bottom: tr(16);
                            line-height: 1.3;
                            background: $color-gr-bbg;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;

                            @media (max-width: 1199.98px) {
                                font-size: 4.5vw;
                                margin-bottom: 1.5881vw;
                            }
                            
                            @media (max-width: 767.98px) {
                                font-size: 6.5vw;
                            }
                        }

                        ._subtitle {
                            margin-bottom: tr(34);
                            max-width: tr(440);

                            @media (max-width: 1199.98px) {
                                margin-bottom: 3.37469vw;
                                font-size: 2.2vw;
                            }

                            @media (max-width: 767.98px) {
                                margin-bottom: 0;
                                font-size: 3vw;
                            }
                        }

                        .link-btn {
                            @media (max-width: 991.98px) {
                                height: tr(40);
                                line-height: tr(38);
                                font-size: tr(14);
                                max-width: tr(200);
                            }

                            @media (max-width: 767.98px) {
                                position: absolute;
                                bottom: tr(-56);
                                max-width: tr(540 - 24)
                            }

                            @media (max-width: 575.98px) {
                                max-width: calc(100% - tr(32));
                            }
                        }
                    }
                }
            }
        }

        .hero-pagination {
            @media (min-width: 992px) {
                position: absolute;
                top: 50%;
                right: tr(64);
                transform: translateY(-50%);
                z-index: 9999;
                flex-direction: column;
    
                .swiper-pagination {
                    &-bullet {
                        width: tr(4);
                        height: tr(16);
                        margin: tr(4) 0;
                
                        &-active {
                            background-color: $color-accent;
                            width: tr(4);
                            height: tr(32);
                        }
                    }    
                }
            }

            @media (max-width: 991.98px) {
                justify-content: center;
            }
        }
    }
}

.header-overview {
    position: relative;
    z-index: 1;

    @media (min-width: 992px) {
        margin: tr(-32) 0 tr(56);
    }

    @media (max-width: 991.98px) {
        margin-top: tr(36);
    }

    @media (max-width: 767.98px) {
        .container {
            max-width: inherit;
            padding: 0;
        }
    }

    &__card {
        position: relative;
        padding: tr(53) 0 tr(42);
        background: $color-gr-bbg;
        border-radius: $rounded-xlarge;
        overflow: hidden;
        color: white;

        @media (max-width: 767.98px) {
            border-radius: 0;
        }

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url('../img/header-overlay.svg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        .stats-slider {
            position: relative;

            @media (max-width: 767.98px) {
                width: calc(100% - tr(64));
            }

            .swiper-slide {
                text-align: center;
                width: tr(156);

                @media (max-width: 575.98px) {
                    opacity: 0;
                    transition: all .2s ease-out;
    
                    &.swiper-slide-active,
                    &.swiper-slide-next {
                        opacity: 1;
                    }
                }

                i {
                    font-size: tr(64);
                    margin-bottom: tr(16);
                    display: inline-block;
                }
            }

            @media (max-width: 991.98px) {

                .swiper-slide {

                    i {
                        font-size: tr(40);
                    }

                    .h6 {
                        font-family: $font-family;
                        font-weight: normal;
                        font-size: tr(12);
                    }

                    .h3 {
                        font-size: tr(24);
                    }
                }
            }
        }

        button {
            cursor: pointer;
            z-index: 2;
            position: absolute;
            border: none;
            top: 50%;
            transform: translateY(-50%);
            width: tr(32);
            height: tr(32);
            border-radius: $rounded-circle;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            backface-visibility: hidden;
            transition: all .3s $cbzr;

            &[disabled] {
                opacity: .4;
            }

            &#statsBtnLeft {
                left: tr(16);
                
                @media (max-width: 767.98px) {
                    left: tr(-24);
                }
            }

            &#statsBtnRight {
                right: tr(16);

                @media (max-width: 767.98px) {
                    right: tr(-24);
                }
                
                i {
                    padding-left: tr(3);
                }
            }

            i {
                font-size: tr(24);
                color: $color-accent;
            }

            &:hover {
                opacity: .8;
                transform: translateY(-50%) scale(1.1);

                &[disabled] {
                    opacity: .4;
                    transform: translateY(-50%) scale(1);
                }
            }
        }
    }
}