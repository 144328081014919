.progress {
    overflow: unset;
    height: tr(4);
    background-color: $color-gray-light;
    position: relative;
    border-radius: $rounded-full;

    .progress-bar {
        overflow: unset;
        border-radius: tr(100);
        background-color: $color-secondary;
        position: relative;
        border-radius: $rounded-full;

        i {
            font-size: tr(20);
            color: $color-secondary;
            position: absolute;
            right: tr(-6);
            top: tr(-19);
            transition: all .2s ease-in-out;
        }

        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            right: tr(-4);
            transform: translateY(-50%);
            height: tr(8);
            width: tr(8);
            background-color: $color-secondary;
            border-radius: $rounded-circle;
        }

        // &::after {
        //     display: block;
        //     font-size: tr(10);
        //     position: absolute;
        //     top: -30px;
        //     right: tr(-15);
        //     height: tr(20);
        //     line-height: tr(20);
        //     min-width: tr(30);
        //     padding: 0 4px;
        //     background-color: $color-secondary;
        //     border-radius: $rounded-small;
        //     transition: all .2s ease-in-out;
        // }

        // &[progress-fund]::after { 
        //     content: attr(progress-fund);
        // }

        &.ofr {
            i {
                font-size: tr(22);
                transform: rotate(90deg) translate(tr(-4), tr(4));
            }

            &::after {
                right: tr(1);
            }
        }

        &.ofl {

            i {
                font-size: tr(22);
                transform: rotate(-90deg) translate(tr(4), tr(4));
            }

            &::after {
                right: tr(-30);
            }
        }
    }

    &--finish {

        .progress-bar {
            background-color: $color-success;

            i {
                color: $color-success;
            }

            &::before {
                background-color: $color-success;
            }

            &::after {
                background-color: $color-success;
            }
        }
    }
}