.Dashboard 
    min-height: calc(100vh - 64px)

    .sc-dashboard

        .main-content
            padding-top: tr(40)

            @media (max-width: 767.98px)
                padding-top: tr(24)
                
            @media (max-width: 991.98px)
                margin-bottom: tr(62)
            

            .box
                min-height: tr(400)
                display: flex

                @media (min-width: 992px)
                    gap: tr(40)

                aside
                    position: relative
                    width: 20rem
                    transition: all .2s ease-out

                    &::before
                        display: block
                        position: absolute
                        content: ''
                        min-height: 100vh
                        height: calc(100% + 3rem)
                        width: 100vw
                        right: 0
                        top: tr(-48)
                        background-color: $color-gray-lightest
                        z-index: 0

                    .aside-button
                        cursor: pointer
                        position: absolute
                        z-index: 2
                        top: 0
                        right: 0
                        box-shadow: -0.125rem 0 0.125rem rgb(79 104 114 / 12%)
                        border: none
                        background-color: white
                        padding: 0.375rem 0.625rem
                        border-radius: 0.25rem 0 0 0.25rem
                        transition: all .2s ease-out

                        &:hover
                            opacity: .6
                &-side
                    position: relative
                    height: 100%
                    z-index: 1
                    padding-right: tr(64)

                    @media (max-width: 1199.98px)
                        padding-right: tr(32)


                    &__wrapper
                        margin-bottom: tr(32)

                        ul

                            li

                                &:not(:last-child)
                                    margin-bottom: tr(8)

                                a
                                    display: block
                                    font-size: tr(16)
                                    color: $color-base2
                                    padding: tr(12) 0
                                    border-radius: $rounded-small
                                    position: relative
                                    transition: all .2s ease-out

                                    i
                                        font-size: tr(24)
                                        position: absolute
                                        left: tr(16)
                                        top: 50%
                                        transform: translateY(-50%)

                                    span 
                                        margin-left: tr(56)
                                        transition: all .2s ease-out
                                        visibility: visible
                                        opacity: 1
                                        -webkit-line-clamp: 1
                                        overflow: hidden
                                        display: -webkit-box
                                        -webkit-box-orient: vertical

                                    &:hover
                                        background-color: mix($color-accent, white, 20%)

                                &._active

                                    a
                                        color: $color-accent
                                        font-weight: $fw-bold

                                        &::before
                                            display: block
                                            position: absolute
                                            content: ''
                                            top: 0
                                            left: 0
                                            width: tr(3)
                                            height: 100%
                                            border-radius: $rounded-small
                                            background-color: $color-accent

                    &__profile 

                        .avatar
                            transition: all .2s ease-out

                        &__text
                            transition: all .2s ease-out

                            div 
                                font-size: tr(18)
                                font-weight: $fw-bold
                                font-family: $font-family2
                                margin-bottom: tr(8)

                            span
                                font-size: tr(16)
                                color: $color-base2

                    @media (max-width: 991.98px) 
                        display: none
            
                &__content
                    width: 100%
                    flex: 1

                &--collapsed

                    .aside-button
                        padding: 0.375rem 1rem !important

                    aside
                        width: 3.5rem

                    .avatar.avatar__xxl
                        margin-top: 4rem
                        margin-left: 0.375rem
                        height: 2.5rem
                        width: 2.5rem

                    .box-side__profile__text,
                    .box-side .input-pack
                        display: none

                    .box-side__wrapper span
                        opacity: 0
                        visibility: hidden

            ._main-dashboard
                padding-bottom: tr(40)

                @media (max-width: 991.98px)
                    padding-bottom: tr(32)

                .grid
                    @media (max-width: 991.98px)
                        gap: tr(16)

                .tab-wrapper
                    border-bottom: tr(1) solid $color-gray

                ._wrapper
                    padding-top: tr(32)

            ._content 
                margin-top: tr(24)
                
                ._show
                    font-size: tr(16)
                    color: $color-base2
                    display: block
                    margin-bottom: tr(24)

            .input-card .tab-wrapper 
                @media (max-width: 575.98px) 
                    margin: 0 tr(-24) tr(24)

        .cta-card
            margin: tr(32) 0 tr(48)

        .pagination-wrapper
            display: flex
            justify-content: space-between
            align-items: center

            @media (max-width: 767.98px)
                justify-content: center

        .tracking
            border: tr(1) solid $color-gray
            padding: tr(16)
            border-radius: $rounded-large
            overflow-y: auto
            max-height: calc(100vh - tr(316))

            ul

                li
                    position: relative
                    padding-left: tr(24)

                    &::before,
                    &:after
                        content: ''
                        position: absolute
                        display: block

                    &::before
                        width: tr(8)
                        height: tr(8)
                        border-radius: $rounded-circle
                        background-color: rgba($color-accent, .4)
                        left: 0
                        top: tr(8)
                    
                    &::after
                        top: tr(16)
                        left: tr(3)
                        height: calc(100% - tr(8))
                        width: tr(1)
                        border: tr(1) dashed rgba($color-accent, .4)
                    
                    ._title
                        display: block
                        font-weight: $fw-bold
                        font-size: tr(16)

                        @media (max-width: 767.98px)
                            font-size: tr(14)
                        
                    .ns
                        display: block
                        font-weight: bold
                        font-size: tr(14)

                        @media (max-width: 767.98px)
                            font-size: tr(12)
                        
                    ._time
                        color: $color-base2
                        @media (max-width: 767.98px)
                            font-size: tr(12)

                    ._time2
                        font-weight: bold
                        color: $color-base
                        font-size: tr(14)


                    &:not(:last-child)
                        padding-bottom: tr(32)
                    

                    &:first-child

                        ._title
                            color: $color-accent
                        

                        &::before
                            background-color: $color-accent

            &.-withdraw
                ul
                    li
                        &:first-child
                            ._title
                                color: $color-base
                            
                        

    @media (max-width:575.98px)
        &::after 
            content: none