.Kajian {

    .kajian-head {
        padding: tr(40) 0 0;
    
        > * {
            margin-bottom: tr(-96);
        }
    
        ._title {
            margin-bottom: tr(24);
    
            @media (max-width: 1199.98px) {
                margin-bottom: 1rem;
            }
        }
    
        &__img {
            height: tr(398);
            border-radius: $rounded-xlarge;
            width: 100%;
            overflow: hidden;
            position: relative;
    
            .plan-card {
                height: 100%;
            }

            @media (max-width: 991.98px) {
                height: auto;
            }
    
            @media (max-width: 767.98px) {
                border-radius: $rounded-large;
                // height: tr(320);
                 aspect-ratio: 1/1;
            }
    
            // @media (max-width: 575.98px) {
            //     height: 79vw;
            // }

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: right;

                @media (max-width: 767.98px) {
                    object-fit: cover;
                    object-position: center;
                }
            }

            &__overlay {
                display: flex;
                align-items: flex-start;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                padding: 2.5rem;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, #eef2f3 52%, rgba(#eef2f3, 0.7) 80%, rgba(255, 255, 255, 0) 100%);
    
                ._title {
                    max-width: 50%;
                    margin-bottom: 0;

                    .badges {
                        padding: 0.5rem 1rem;
                        font-size: 0.875rem;
                        // position: absolute;
                        // top: 2rem;
                    }
    
                    .h4 {
                        background: linear-gradient(90deg, #389ED9 1.35%, #B1CF1F 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        color: $color-accent;
                        line-height: 1.3;
                    }
                }
            }
        }
    }

    .kajian-body {
        background-color: white;
        padding-bottom: tr(80);
        position: relative;
        padding: tr(56+96) 0 tr(80);
    
        &__main {
            background-color: white;
    
            .nav-tabs {
    
                .nav-item {
                    max-width: 20.2rem;
                }
    
                @media (max-width: 1199.98px) {
                    justify-content: center;
                }
            }
        }
    
        .tab-content {
            // border-top: tr(1) solid $color-gray-light;
    
            .donatur-card--large {
    
                &:first-child {
                    padding-top: 0;
                }
            }
    
    
            .main-collapse {
                margin-top: tr(-24);
            }
        }
    
        ._wrapper {
            // padding: tr(48) 0 0;
    
            @media (max-width: 767.98px) {
                padding: tr(24) 0 0;
            }
    
            ._top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // padding-top: tr(16);
                padding-bottom: tr(32);
    
                h3 {
                    padding-right: tr(16);
                }
    
                a {
                    margin: 0;
                }
    
                @media (max-width: 767.98px) {
                    flex-direction: column;
    
                    h3 {
                        padding-right: 0;
                        padding-bottom: tr(16);
                    }
                }
            }
        }
        article {
        }
    
        &__donatur-wrapper {
            background-color: white;
            padding: tr(24) tr(16);
        }
    }

    &--custom-bg {
        background-size: cover;
        background-position: center center;

        .kajian-head,
        .kajian-body {
            background-color: unset;

        }
    }
}