.selected-campaign {
    padding: tr(64) 0 tr(80);
    background-color: rgba($color-gray-light, .2);

    .container {
        .sc-header {
            margin-bottom: tr(24);

            @media (max-width: 991.98px) {
                margin-bottom: tr(32);
            }
        }
    }

    .campaign-lists {
        position: relative;
        overflow: hidden !important;

        .swiper-slide {
            padding: tr(8) 0 tr(40);
        }

        .campaign-pagination {
            justify-content: center;
        }
    }

    .campaign-lists-mb {
        gap: tr(16);
    }

    @media (max-width:991.98px) {
        padding: tr(54) 0;

        .container {

            &::before,
            &::after {
                content: unset;
            }
        }
    }

    .additional-programs {
        padding-top: tr(64);

        @media (max-width: 767.98px) {
            padding-top: tr(32) !important;
        }
    }
    
    @media (max-width:767.98px) {
        padding: tr(40) 0 tr(32);
    }
}