.animate-live-ping
    position: relative
    z-index: 1

    &::before
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: $color-error
        border-radius: $rounded-full
        display: block
        content: ''
        animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite
        z-index: -1

@keyframes ping
    75%, 100%
        transform: scale(1.2, 1.3)
        opacity: 0