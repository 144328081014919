.article-lite-card {
    border: tr(1) solid $color-gray-light;
    border-radius: $rounded-large;
    padding: tr(24);
    background-color: white;
    position: sticky;
    top: tr(96);

    @media (max-width: 767.98px) {
        border-radius: $rounded-large;
    }

    h2 {
        margin-bottom: tr(8);
    }

    &__each {
        display: flex;
        padding: tr(16) 0;
        color: $color-base;
        text-decoration: none !important;
        transition: all .2s;

        &:not(:last-child) {
            border-bottom: tr(1) solid $color-gray-light;
        }

        &:last-child {
            padding-bottom: 0;
        }

        &:hover {
            color: $color-base;
    
            @media (min-width:992px) {
                transform: translateY(tr(-3));
            }
        }
    }

    ._content {
        flex: 1;

        span {
            color: $color-base2;
        }
    }

    &__title {
        font-family: $font-family2;
        font-size: tr(18);
        font-weight: $fw-bold;
        max-height: tr(54);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        margin-bottom: tr(8);
    }
}