.kajian-card {
    border: tr(1) solid $color-gray-light;
    border-radius: $rounded-small;
    // box-shadow: $shadow;
    background-color: white;
    overflow: hidden;
    display: block;
    color: $color-base;
    box-shadow: $shadow-blue;
    backface-visibility: hidden;
    transition: all .2s ease-in-out;
    
    &:hover {
        text-decoration: none;

        @media (min-width:992px) {
            // box-shadow: $shadow20;
            transform: translateY(tr(-4)) scale(1.001);
            // transform: scale(1.004) translateY(tr(-3));
        }
    }

    &:active {
        @media (min-width:992px) {
            box-shadow: $shadow10;
            // transform: scale(1) translateY(0);
            transition: all .1s ease-in-out;
        }
    }

    &__img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;

        // @media (max-width: 575.98px) {
        //     height: 50vw;
        // }
    }

    &__description {
        padding: tr(24);

        @media (max-width: 575.98px) {
            padding: tr(14);
        }

        &__theme {
            font-size: tr(14);
            margin-bottom: tr(4);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        &__title {
            font-family: $font-family2;
            font-size: tr(18);
            font-weight: $fw-bold;
            margin-bottom: tr(16);
            color: $color-base;
            height: tr(54);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        &__date {
        }

        &__time {
            font-size: tr(14);
        }
    }

}