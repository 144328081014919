.sc-program-list {
    @extend .sc-bg;
    padding: 0 0 tr(80);

    @media (max-width: 575.98px) {
        padding: 0 0 tr(40);
    }

    .program-card {
        // margin-bottom: tr(24);

        // @media (max-width:575.98px) {
        //     margin-bottom: tr(16);
        // }
    }

    // .pagination {
    //     margin-top: 4.2rem;
    //     margin-bottom: tr(16);
    // }

    // .cta-card {
    //     margin: tr(32) 0 0;
    // }
}