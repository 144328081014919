.cta {
    padding: tr(80) 0;

    @media (max-width:991.98px) {
        padding: tr(32) 0 tr(32);
    }
    
    @media (max-width: 767.98px) {
        // padding: 0 0 tr(12);
        padding: 0;
        background-color: rgba($color-gray-light, .2);

        .container {
            max-width: inherit;
            padding: 0;
        }
    }
}