.Projects {
    .sc-project-list {
        @extend .sc-bg;
        padding: 0 0 tr(80);

        .topheader {
            margin-bottom: tr(80);

            @media (max-width:991.98px) {
                margin-bottom: tr(40);
            }

            @media (max-width:575.98px) {
                margin-bottom: tr(24);
            }
        }
    
        @media (max-width: 575.98px) {
            padding: 0 0 tr(40);
        }
    }

    .cta {
        padding: tr(24) 0 tr(80);
    
        @media (max-width:991.98px) {
            padding: tr(32) 0 tr(32);
        }
        
        @media (max-width: 767.98px) {
            // padding: 0 0 tr(12);
            padding: 0;
            background-color: rgba($color-gray-light, .2);
    
            .container {
                max-width: inherit;
                padding: 0;
            }
        }
    }
}