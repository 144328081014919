@mixin link-btn-mx {
    display: inline-block;
    text-align: center;
    border: tr(1) solid;
    border-radius: $rounded-full;
    outline: none !important;
    cursor: pointer;
    position: relative;
    transition: all .3s;
    backface-visibility: hidden;
    padding-left: tr(8);
    padding-right: tr(8);
    box-shadow: $shadow20;

    i,
    img {
        font-size: tr(24);
        height: tr(24);
        position: relative;
        margin-right: .5rem;

        &._left {
            font-size: tr(24);
            position: absolute;
            left: tr(8);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    i {
        top: tr(4);
    }

    // span {
    //     margin-left: tr(32);
    // }

    &:hover,
    &:active {
        text-decoration: none;
    }

    @media (min-width:768px) {
        &:hover {
            box-shadow: $shadow40;
            filter: brightness(110%);
        }
    
        &:active {
            box-shadow: $shadow20;
            filter: brightness(90%);
            transition: all .1s;
        }
    }
}

@mixin link-btn-color-mx($text-btn-color, $btn-color, $border-btn-color) {
    color: $text-btn-color !important;
    background-color: $btn-color;
    border-color: $border-btn-color;
    // &:hover {
    //     color: $text-btn-color;
    //     background-color: mix(black, $btn-color, 10%);
    //     border-color: mix(black, $border-btn-color, 10%);
    // }
    // &:active {
    //     background-color: mix(black, $btn-color, 20%);
    //     border-color: mix(black, $border-btn-color, 20%);
    // }
}