.Loreg {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "visual forms";

    @media (max-width: 767.98px) {
        grid-template-columns: 1fr;
        grid-template-areas: "visual" "forms";
    }

    &__visual {
        grid-area: visual;
        height: 100vh;
        width: 100%;
        object-fit: cover;

        @media (max-width: 767.98px) {
            display: none;
        }
    }

    .sc-form {
        background-color: white;
        grid-area: forms;
        height: 100vh;
        padding: 0 tr(24);
        overflow-y: auto;
        background-image: url('../img/loreg-path.svg');
        background-repeat: no-repeat;
        background-position: top right;
        background-size: auto tr(88);
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        justify-content: center;
        align-items: center;

        @media (max-width: 767.98px) {
            background-size: auto tr(64);
        }

        .input-card {
            background-color: transparent;
            padding: tr(92) 0;
            max-width: tr(392);
            margin: 0 auto;
            width: 100%;
            box-shadow: unset;
            border-radius: unset !important;

            .link-btn {
                max-width: tr(392);
            }

            @media (max-width: 767.98px) {
                margin: 0 auto;
                padding: tr(72) 0 tr(32);
            }
        }
    }
}
