.additional-program
    display: flex
    color: $color-base

    .pattern-icon
        width: tr(80)
        height: tr(80)

        i
            font-size: tr(40)

    &__desc
        flex: 1
        margin-left: tr(24)

        .h5
            line-height: 1
            margin-bottom: tr(8)

        p
            color: $color-base2
            margin-bottom: tr(24)

        strong
            color: $color-accent

    &:hover
        color: $color-base

    @media (max-width: 1199.98px)
        flex-direction: column

        &__desc
            margin-left: 0
            margin-top: tr(24)

    @media (max-width: 991.98px)
        padding: tr(12)
        align-items: center
        text-align: center
        background-color: white
        border: tr(1) solid $color-gray-light
        box-shadow: $shadow-blue
        border-radius: $rounded-small

        &__desc
            margin-top: tr(16)

            .h5
                margin-bottom: 0
                font-weight: normal
                line-height: 1.3

            p
                display: none

            strong
                display: none

    @media (max-width: 767.98px)
        .pattern-icon
            width: tr(56)
            height: tr(56)

            i
                font-size: tr(28)

        &__desc

            .h5
                font-size: tr(14)