.program-body {
    background-color: white;
    padding-bottom: tr(80);
    position: relative;
    padding: tr(56+96) 0 tr(80);

    &__main {
        background-color: white;

        .nav-tabs {

            .nav-item {
                max-width: 20.2rem;
            }

            @media (max-width: 1199.98px) {
                justify-content: center;
            }
        }
    }

    .tab-content {
        // border-top: tr(1) solid $color-gray-light;

        .donatur-card--large {

            &:first-child {
                padding-top: 0;
            }
        }


        .main-collapse {
            margin-top: tr(-24);
        }
    }

    ._wrapper {
        padding: tr(48) 0 0;

        @media (max-width: 767.98px) {
            padding: tr(24) 0 0;
        }

        ._top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding-top: tr(16);
            padding-bottom: tr(32);

            h3 {
                padding-right: tr(16);
            }

            a {
                margin: 0;
            }

            @media (max-width: 767.98px) {
                flex-direction: column;

                h3 {
                    padding-right: 0;
                    padding-bottom: tr(16);
                }
            }
        }
    }
    article {
    }

    &__donatur-wrapper {
        background-color: white;
        padding: tr(24) tr(16);
    }
}