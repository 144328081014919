.right {
    float: right !important;
}

.cursor-pointer {
    cursor: pointer;
}

@for $i from 1 through 20 {
    // Margin
    .ma {
        // Top
        &to { 
            &-#{$i} {
                margin-top: $egs * $i !important;
            }
        }
        // Bottom
        &bo { 
            &-#{$i} {
                margin-bottom: $egs * $i !important;
            }
        }
        // Left
        &le { 
            &-#{$i} {
                margin-left: $egs * $i !important;
            }
        }
        // Right
        &ri { 
            &-#{$i} {
                margin-right: $egs * $i !important;
            }
        }
    }
    .pa {
        // Top
        &to { 
            &-#{$i} {
                padding-top: $egs * $i !important;
            }
        }
        // Bottom
        &bo { 
            &-#{$i} {
                padding-bottom: $egs * $i !important;
            }
        }
        // Left
        &le { 
            &-#{$i} {
                padding-left: $egs * $i !important;
            }
        }
        // Right
        &ri { 
            &-#{$i} {
                padding-right: $egs * $i !important;
            }
        }
    }

    .m {
        // Top
        &t { 
            &-#{$i} {
                margin-top: .25rem * $i !important;
            }
        }
        // Bottom
        &b { 
            &-#{$i} {
                margin-bottom: .25rem * $i !important;
            }
        }
        // Left
        &l { 
            &-#{$i} {
                margin-left: .25rem * $i !important;
            }
        }
        // Right
        &r { 
            &-#{$i} {
                margin-right: .25rem * $i !important;
            }
        }
    }
    .p {
        // Top
        &t { 
            &-#{$i} {
                padding-top: .25rem * $i !important;
            }
        }
        // Bottom
        &b { 
            &-#{$i} {
                padding-bottom: .25rem * $i !important;
            }
        }
        // Left
        &l { 
            &-#{$i} {
                padding-left: .25rem * $i !important;
            }
        }
        // Right
        &r { 
            &-#{$i} {
                padding-right: .25rem * $i !important;
            }
        }
    }
}

.f {

    &f {
        &-1 {
            font-family: $font-family;
        }

        &-2 {
            font-family: $font-family2;
        }
    }

    &w {

        &-bold {
            font-weight: $fw-bold !important;
        }
    } 

    &c {

        &-base {

            color: $color-base !important;
        }

        &-base2 {

            color: $color-base2 !important;
        }

        &-gray {

            color: $color-gray !important;
        }

        &-accent {

            color: $color-accent !important;
        }

        &-secondary {

            color: $color-secondary !important;
        }
        
        &-primary {

            color: $color-primary !important;
        }

        &-success {

            color: $color-success !important;
        }

        &-info {

            color: $color-info !important;
        }

        &-warning {

            color: $color-warning !important;
        }

        &-error {

            color: $color-error !important;
        }
    }

    &s {

        &-p {

            &-2 {

                font-size: tr(18);
            }
        }

        &-m {

            &-2 {
                font-size: tr(14);
            }
        }

        &-xs {
            font-size: tr(12);
        }
        
        &-sm {
            font-size: tr(14);
        }

        &-base {
            font-size: tr(16);
        }

        &-lg {
            font-size: tr(18);
        }

        &-xl {
            font-size: tr(20);
        }

        &-2xl {
            font-size: tr(24);
        }

        &-3xl {
            font-size: tr(32);
        }
    }
}

.bgcolor- {
    &secondary {
        background-color: $color-secondary;
    }
    &tertiary {
        background-color: $color-tertiary;
    }
    &accent {
        background-color: $color-accent;
    }
}

.bgc {
    &-2 {
        background-color: $color-bg2 !important;
    }
}

.align-center {
    text-align: center;
}

.content-center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.img-responsive {
    width: 100%;
}

.truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shadow {
    
    &10 {

        box-shadow: $shadow10;
    }
    &20 {

        box-shadow: $shadow20;
    }
    &40 {

        box-shadow: $shadow40;
    }
    &60 {

        box-shadow: $shadow60;
    }
}

// color-body
.cbd {

    color: $color-base;
    opacity: .8;
}

.reset-tab {

    &.nav-tabs {
        // border-bottom: 0;
        display: flex;

        .nav-item {
            flex-grow: 1;
            text-align: center;
        }

        .nav-link {
            padding: 0;
            border: none;

            &:hover,
            &.active {
                
                border: none;
            }
        }
    }
}

.main-tab {

    &.nav-tabs {
        border: none;

        .nav-link {
            position: relative;
            cursor: pointer;
            width: 100%;

            span {
                display: block;
                color: $color-base2;
                font-size: tr(16);
                margin: tr(10) 0;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                &::after {
                    display: inline-block;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: tr(-10);
                    width: 100%;
                    height: 0;
                    background-color: $color-accent;
                    border-radius: tr(200) tr(200) 0 0;
                }

                > div {
                    margin-left: tr(8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: tr(10);
                    font-weight: bold;
                    color: white;
                    background-color: $color-base2;
                    border-radius: $rounded-circle;
                    width: tr(20);
                    height: tr(20);

                    > div {
                        line-height: 1;
                    }
                }

                @media (max-width: 767.98px) {
                    font-size: tr(13);
                }
            }

            &.active {

                span {
                    // color: $color-secondary;
                    font-weight: $fw-bold;
                    border-color: $color-accent;

                    // > div {
                    //     background-color: $color-secondary;
                    // }

                    &::after {
                        height: tr(3);
                    }
                }

            }
        }
    }
}

.de-tab {

    &.nav-tabs {

        .nav-link {
            color: $color-base;
            margin-right: tr(32);
            border-bottom: tr(4) solid transparent;
            padding-bottom: tr(16);
            opacity: .8;

            &:hover {
                border-color: transparent;
            }

            &.active {
                color: $color-primary;
                border-bottom: tr(4) solid $color-primary;
                opacity: 1;

                &:hover {
                    border-color: $color-primary;
                }
            }
        }
    }

    @media (max-width: 991.98px) {

        &.nav-tabs {

            .nav-link {
                margin-right: tr(24);
                padding-bottom: tr(16);

                h5 {
                    font-size: tr(18);
                }
            }
        }
    }

    @media (max-width: 767.98px) {

        &.nav-tabs {

            .nav-link {
                padding-bottom: tr(8);
            }
        }
    }

    @media (max-width: 575.98px) {
        margin-top: tr(8);
    }
}

.ver-tab {
    display: block !important;
    border: unset;

    &.nav-tabs {

        .nav-link {
            margin-right: unset;
            border: unset;
            text-align: left;

            &:hover {
                border-color: transparent;
            }

            &.active {
                background-color: $color-accent;
                color: white;

                &:hover {

                }
            }
        }
    }

    @media (max-width: 991.98px) {

        &.nav-tabs {

            .nav-link {
                // margin-right: tr(24);
                padding-bottom: tr(16);

                h5 {
                    font-size: tr(18);
                }
            }
        }
    }

    @media (max-width: 767.98px) {

        &.nav-tabs {

            .nav-link {
                padding-bottom: tr(8);
            }
        }
    }

    @media (max-width: 575.98px) {
        margin-top: tr(8);
    }
}

.main-title {
    
    h5 {
        display: flex;
        justify-content: space-between;
    }
}

.main-subtitle {
    display: flex;
    align-items: center;
    
    p {
        font-size: tr(18);
        // font-weight: $fw-bold;
    }

    span {
        display: block;
        font-size: tr(10);
        font-weight: bold;
        color: white;
        background-color: $color-accent;
        border-radius: $rounded-circle;
        width: tr(20);
        height: tr(20);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: tr(8);
    }
}

.main-collapse {

    &__wrapper {
        border-bottom: tr(1) solid $color-gray-light;
        // overflow: hidden;
        position: relative;

        margin-left: tr(20);

        &::before,
        &::after {
            display: block;
            position: absolute;
            content: '';
            
        }

        &::after {
            width: tr(9);
            height: tr(9);
            border-radius: $rounded-circle;
            left: tr(-20);
            top: tr(32);
            background-color: $color-secondary;
        }

        &::before {
            top: tr(34);
            left: tr(-16);
            height: 100%;
            width: tr(1);
            background-color: $color-secondary;
        }

        // &:not(:last-child) {
            // margin-bottom: tr(24);
        // }

        &:first-child {
    
            &::after {
                background-color: $color-accent;
            }
        }

        &:last-child {
    
            &::before {
                top: 0;
                height: tr(36);
            }
        }
    }

   ._main-btn {
       width: 100%;
       padding: 0;
       border: none;
       text-align: left;
       outline: none;
       padding: tr(24) 0;
       position: relative;

        ._title {
            font-size: tr(18);
            font-weight: $fw-bold;
            margin-bottom: tr(8);
            color: $color-base;
            line-height: tr(28);
        }

        ._subtitle {
            font-size: tr(16);
            color: $color-base2;
            line-height: tr(24);
            font-weight: $fw-normal;
        }

        &:after {
            font-family: 'Custom-Icon-by-Ryaanst' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\e907';
            position: absolute;
            font-size: tr(24);
            right:0;
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
            color: $color-base;
            opacity: .8;
            transition: .4s $cbzr;
        }

        &.collapsed {

            &:after {

                transform: translateY(-50%) rotate(270deg);
            }
        }
   } 

    ._body {
        padding: tr(24) 0;
        padding-top: 0;

        article {
            border-bottom: none !important;

            * {

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.mb-o {
    
}

.ds-o {
    display: none;
}

@media (min-width: 992px) {
    .mb-o {
        display: none;
    }
    
    .ds-o {
        display: unset;
    }
}

._copy {
    cursor: pointer;
    font-size: tr(16);
    font-weight: $fw-bold;
    color: $color-accent;
    transition: all .1s ease-in-out;
    position: relative;

    @media (max-width: 767.98px) {
        margin-left: tr(24);
    }

    &::before {
        position: absolute;
        content: 'Tersalin';
        color: white;
        overflow: hidden;
        padding: 0;
        display: block;
        background-color: $color-accent;
        border-radius: $rounded-small;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        transition: all .2s ease-in-out;
    }
    
    // span {
    //     margin-left: tr(6);
    // }

    i {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(tr(-24), -62%);
        transition: all .2s ease-in-out;
    }

    &._copied {

        &::before {
            padding: 0 tr(8);
            width: 100%;
        }

        i {
            left: unset;
            right: 0;
            transform: translate(tr(-8), -54%);
            color: white;
        }
    }

    // &:hover {
    //     transform: translateY(tr(-2));
    // }

    // &:active {
    //     transform: translateY(0);
    // }
}

%heading {
    margin-bottom: 0;
    font-family: $font-family2;
    font-weight: 600;
    line-height: 1.5;
}

.h1 {
    @extend %heading;
    font-size: tr(72);
}

.h2 {
    @extend %heading;
    font-size: tr(56);
}

.h3 {
    @extend %heading;
    font-size: tr(48);
}

.h3_5 {
    @extend %heading;
    font-size: tr(40);
}

.h4 {
    @extend %heading;
    font-size: tr(32);
}

.h5 {
    @extend %heading;
    font-size: tr(24);
}

.h6 {
    @extend %heading;
    font-size: tr(18);
}

.h7 {
    @extend %heading;
    font-size: tr(14);
}


@media (max-width:575.98px) {
    .h3 {
        font-size: tr(32);
    }

    .h3_5 {
        @extend %heading;
        font-size: tr(26);
    }

    .h4 {
        font-size: tr(24);
    }

    .h5 {
        font-size: tr(20);
    }
}

.bdr {

    &b {
        &-w {
            &-1 {
                border-bottom-width: tr(1);
            }
            &-2 {
                border-bottom-width: tr(2);
            }
            &-3 {
                border-bottom-width: tr(3);
            }
            &-4 {
                border-bottom-width: tr(4);
            }
            &-5 {
                border-bottom-width: tr(5);
            }
            &-6 {
                border-bottom-width: tr(6);
            }
        }

        &-s {

            &-solid {
                border-bottom-style: solid;
            }
        }
    
        &-c {
            &-gray {
                border-bottom-color: $color-gray;
            }
        }
    }
}

.intro {
    margin-top: -5rem;
    padding: 5rem 0 3.75rem;
    margin-bottom: 3.75rem;
    background-image: url(../img/pattern-islamic.svg);

    @media (max-width:991.98px) {
        margin-top: -2.5rem;
        padding: 2.5rem 0 2.5rem;
        margin-bottom: 2.5rem;
    }

    @media (max-width:575.98px) {
        margin-top: 1.5rem;
        padding: 1.5rem 0 1.5rem;
        margin-bottom: 1.5rem;
    }
}
.intro2 {
    padding: 5rem 0;
    margin-bottom: 5rem;
    background-image: url(../img/pattern-islamic.svg);

    @media (max-width:991.98px) {
        padding: 2rem 0;
        margin-bottom: 2rem;
    }

    @media (max-width:575.98px) {
        padding: 1.5rem 0;
        margin-bottom: 1.5rem;
    }
}

.aspect-video {
    aspect-ratio: 16 / 12;
}

.aspect-54 {
    aspect-ratio: 5 / 4;
}

// globals
.cqgroup-card {
    width: 100%;
    border: tr(1) solid $color-gray-light;
    border-radius: $rounded-small;
    // box-shadow: $shadow;
    background-color: white;
    overflow: hidden;
    display: block;
    color: $color-base;
    box-shadow: $shadow-blue;
    backface-visibility: hidden;
    transition: all .2s ease-in-out;

    padding: tr(16);

    @media (max-width: 575.98px) {
        padding: tr(14);
    }
    
    &:hover {
        text-decoration: none;

        @media (min-width:992px) {
            // box-shadow: $shadow20;
            transform: translateY(tr(-4)) scale(1.001);
            // transform: scale(1.004) translateY(tr(-3));
        }
    }

    &:active {
        @media (min-width:992px) {
            box-shadow: $shadow10;
            // transform: scale(1) translateY(0);
            transition: all .1s ease-in-out;
        }
    }

    h3 {
        font-family: "Baloo 2", cursive;
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 1rem !important;
        -webkit-line-clamp: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    p {
        font-size: 0.875rem;
        color: $color-base2;
        -webkit-line-clamp: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
}

.g-card {
    padding: 2rem;
    border-radius: $rounded-small;
    background-color: white;
    border: tr(1) solid $color-gray-light;
    border-radius: $rounded-small;
    box-shadow: $shadow-gray;
}

.font-primary {
    font-family: $font-family;
}

.h-full {
    height: 100%;
}

.w-full {
    width: 100%;
}

.lite-donation {
    padding: 2.5rem 0 0;
    position: relative;

    @media (max-width: 565.98px) {
        padding: 1.5rem 0 0;
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .divider {
        height: 100px;
        background-color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;

    }

    table {
        min-width: 25rem;
        width: 100%;

        ._sort {
            border: none;
            outline: none !important;
            padding: 0.125rem 0.25rem;
            color: $color-base2;
            position: absolute;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            
            @media (max-width:767.98px) {
                i {
                    font-size: 0.875rem;
                }
            }
        }

        tr {
            display:table;
            width:100%;
            table-layout:fixed;
        }

        td, th {
            padding: 0.5rem;
            position: relative;
        }

        tbody {
            display:block;
            height:16rem;
            overflow:auto;

            @media (max-width: 575.98px) {
                font-size: 14px;
            }

            tr {
                border-bottom: 1px solid $color-gray-light;

                td {
                    color: $color-base2;
                }
            }
        }

    }
}

.line-clamp-1 {
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.the-tab {
    .swiper-slide {
        width: max-content;
    }
}
.tab-btn {
    border: 1px solid $color-gray;
    display: block;
    text-align: center;
    padding: 0.5rem 1.5rem;
    border-radius: 3.9rem;
    font-size: 0.875rem;
    width: max-content;
    cursor: pointer;

    &._selected {
        background-color: $color-accent;
        border-color: transparent;
        color: white;
    }
}