.cta-card {
    background-image: url('../img/cta-bg.jpg');
    background-size: cover;
    background-position: center center;
    border-radius: $rounded-large;
    position: relative;
    height: tr(272);
    position: relative;
    color: white;
    box-shadow: 0 tr(16) tr(24) tr(-24) rgba(6, 58, 78, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 tr(40);

    > * {
        position: relative;
        z-index: 1;
    }

    &::after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../img/header-overlay.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: $rounded-large;
    }

    @media (max-width: 1199.98px) {
        width: 100%;
        left: 0;
    }

    @media (max-width: 767.98px) {
        &::after {
            border-radius: 0;
        }
    }

    // &::before {
    //     position: absolute;
    //     display: block;
    //     content: '';
    //     left: 0;
    //     top: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: rgba(black, .4);
    // }

    &__title {
        position: relative;
        margin-bottom: tr(56);
        max-width: tr(665);

        h2 {
            line-height: 1.25;
        }
    }

    &__btn {
        width: 100%;
    }

    &--lite {
        border-radius: tr(4) tr(32);
        height: tr(128);
        padding: 0 tr(40);
    }

    @media (max-width: 991.98px) {
        padding: tr(32);
        height: auto;
        display: block;
        // text-align: center;

        &__title {
            width: 100%;
            margin-bottom: tr(24);

            h2 {
                font-size: 3.6vw;
                line-height: 1.3;
            }
        }

        &__btn {
            flex: unset;
            text-align: center;

            .link-btn {
                margin: 0 auto;
                float: unset;
            }
        }
    }

    @media (max-width: 767.98px) {
        padding: tr(40) tr(16);
        border-radius: 0;
    //     margin-bottom: 40px;

        &__title {
            // margin-bottom: 0;
            
            h2 {
                font-size: tr(24);
            }
        }

        &__btn {

            .link-btn {
                max-width: 100%;
                height: tr(40);
                font-size: tr(14);
                line-height: tr(38);
            }
        }
    }

    &--v2 {

        @media (min-width: 992px) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
                background-image: url("../img/mitra-bg.jpg");
    
            .cta-card__title {
                margin-bottom: 0;
                margin-right: tr(96);
            }
        }
    }
}