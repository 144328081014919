@import 'mixins/links';

.link-btn {
    @include link-btn-mx;

    //
    // &-center {
    //     margin-left: auto;
    //     margin-right: auto;
    // }

    &-square {
        border-radius: $rounded-large;
    }

    &-no-shadow {
        box-shadow: none;

        &:hover {
            
        }
    }

    // font size
    &-f-sm {
        font-size: tr(14);
    }

    // height
    &-xs {
        height: tr(40);
        line-height: tr(38);
    }
    &-sm {
        height: tr(48);
        line-height: tr(46);
    }
    &-md {
        height: tr(56);
        line-height: tr(54);
    }
    &-lg {
        height: tr(64);
        font-size: tr(20);
        line-height: tr(62);
    }

    // width
    &-w-xs {
        width: 100%;
        max-width: tr(160);
    }
    &-w-sm {
        width: 100%;
        max-width: tr(180);
    }
    &-w-md {
        width: 100%;
        max-width: tr(328);
    }
    &-w-lg {
        width: 100%;
        max-width: tr(392);
    }
    &-w-full {
        width: 100%;
    }

    //Clear
    &-noshadow {
        box-shadow:unset;
    }

    // color
    &-primary {
        @include link-btn-color-mx(
            white,           // Color
            $color-accent,    // Bg Color
            $color-accent     // Bdr Color
        );
    }
    &-secondary {
        @include link-btn-color-mx(
            white,              // Color
            $color-secondary,     // Bg Color
            $color-secondary      // Bdr Color
        );
    }
    &-primary-o {
        @include link-btn-color-mx(
            $color-accent,       // Color
            white,              // Bg Color
            $color-accent        // Bdr Color
        );
    }
    &-secondary-o {
        @include link-btn-color-mx(
            $color-secondary,       // Color
            white,                // Bg Color
            $color-secondary        // Bdr Color
        );
    }
    &-white-o {
        @include link-btn-color-mx(
            white,       // Color
            transparent,                // Bg Color
            white        // Bdr Color
        );
    }
    &-primary-c {
        @include link-btn-color-mx(
            $color-accent,     // Color
            transparent,        // Bg Color
            transparent         // Bdr Color
        );
    }
    &-secondary-c {
        @include link-btn-color-mx(
            $color-secondary,       // Color
            transparent,            // Bg Color
            transparent             // Bdr Color
        );
    }
    &-clear {
        @include link-btn-color-mx(
            $color-base,     // Color
            transparent,        // Bg Color
            transparent     // Bdr Color
        );
    }
    &-fb {
        @include link-btn-color-mx(
            white, 
            $color-fb,  
            $color-fb 
        );
    }
    &-wa {
        @include link-btn-color-mx(
            white, 
            $color-wa,  
            $color-wa 
        );
    }
    &-gg {
        @include link-btn-color-mx(
            $color-base,
            white,  
            $color-gray-light
        );
    }

    &[disabled] {
        box-shadow: none;
        cursor: not-allowed;

        @include link-btn-color-mx(
            white,
            $color-gray-light, 
            $color-gray-light
        );

        &:hover,
        &:focus {
            filter: brightness(100%);
        }
    }
}