.static {

    &__header {
        background-color: $color-primary;
        color: white;
        text-align: center;
        
        h2 {
            padding: tr(64) 0;

            @media (max-width:991.98px) {
                padding: tr(54) 0;
            }
            
            @media (max-width:767.98px) {
                padding: tr(48) 0;
            }
            
            @media (max-width:575.98px) {
                padding: tr(32) 0;
            }
        }
    }

    &__body {
        padding: tr(64) 0;
        
        @media (max-width:991.98px) {
            padding: tr(54) 0;
        }
        
        @media (max-width:767.98px) {
            padding: tr(48) 0;
        }
        
        @media (max-width:575.98px) {
            padding: tr(32) 0;
        }
    }
}