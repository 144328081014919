.sc-form {
    padding: tr(80) 0 tr(128);

    @media (max-width:991.98px) {
        padding: tr(54) 0;
    }
    
    @media (max-width:767.98px) {
        padding: tr(48) 0;
    }
    
    @media (max-width:575.98px) {
        padding: tr(15) 0;
    }

    // @media (min-width: 992px) {
    //     background-image: url('../img/ornament.svg'), url('../img/illustration-sedekah.svg');
    //     background-repeat: no-repeat;
    //     background-position: left top, right top;
    //     background-size: tr(336) auto;
    // }

    &--auth {
        min-height: calc(100vh - tr(64));

        @media (max-width:991.98px) {
            min-height: calc(100vh - tr(56));
        }
    }

    h2._title {
        margin-bottom: tr(24);
    }

    .input-card {

        &--distance {
            margin-bottom: tr(40);
        }
        
        h3 {
            margin-bottom: tr(40);

            // span {
            //     font-family: $font-family;
            //     font-weight: normal;
            //     color: $color-base2;
            // }
        }

        ._or {
            font-size: tr(16);
            text-align: center;
            // margin: tr(24) 0 tr(16);
            color: $color-base2;
        }

        .invoice {

            &__title-sm {
                font-family: $font-family2;
                font-weight: $fw-bold;
                font-size: tr(18);
            }
    
            &__total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
    
                .rp {
                    font-size: tr(32);
                    font-weight: $fw-bold;
                }

                @media (max-width: 767.98px) {
                    display: block;
                }
            }
    
            &__bank {
                border: tr(1) solid $color-gray;
                padding: tr(16);
                border-radius: $rounded-small;
                align-items: center;
                margin: tr(16) 0 tr(24);

                ._number {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    @media (max-width: 767.98px) {
                        display: block;
                    }
                }

                ._details {
                    color: $color-base2;

                    p {

                        span {
                            font-weight:$fw-bold;
                        }

                        &:not(:last-child) {
                            margin-bottom: tr(4);
                        }
                    }
                }
    
                ._anum {
                    font-weight: $fw-bold;
                    font-size: tr(24);
                }
            }
    
            &__warning {
                background-color: mix(white, $color-warning, 80%);
                font-size: tr(16);
                line-height: tr(24);
                padding: tr(16);
                border-radius:$rounded-small;
                margin: tr(24) 0;
            }
    
            &__due {
                margin-bottom: tr(40);
            }
    
            &__share-label {
                margin: tr(56) 0 tr(40);
                font-size: tr(16);
                text-align: center;
            }
        }
    }

    .breakl {
        height: tr(15);
        background-color: $color-gray;
        margin: tr(40) tr(-40);

        @media (max-width: 575.98px) {
            margin: tr(24) tr(-16);
        }
    }

    .user-logged {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: tr(24);

        @media (max-width: 767.98px) {
            flex-direction: column;
            align-items: flex-start;
        }

        &__name {

            h3 {
                margin-bottom: tr(4);
            }

            span {
                color: $color-base2;
                font-size: tr(16);
            }
        }

        .cb {
            margin-bottom: 0;

            @media (max-width: 767.98px) {
                margin-top: tr(8);
            }
        }
    }
}

.other-collapse {

    &__wrapper {
        border-radius: 0.5rem;
        background-color: white;
        border: tr(1) solid #E6E5E5;
        // margin-bottom: tr(24);
        overflow: hidden;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

   ._main-btn {
       width: 100%;
       padding: 0;
       border: none;
       text-align: left;
       outline: none;
       padding: 1rem 1.5rem;
       position: relative;
       transition: all .2s ease-in-out;

       ._top {
            position: relative;
            display: flex;
            align-items: center;
            gap: 1rem;

            @media (max-width: 575.98px) {
                display: block;
            }

           ._title {
               font-size: tr(20);
               font-weight: bold;
               color: $color-base;
               line-height: 1;
    
               @media (max-width: 575.98px) {
                   font-size: 1rem;
               }
           }
    
           &:after {
               font-family: 'Custom-Icon-by-Ryaanst' !important;
               speak: none;
               font-style: normal;
               font-weight: normal;
               font-variant: normal;
               text-transform: none;
               line-height: 1;
               -webkit-font-smoothing: antialiased;
               -moz-osx-font-smoothing: grayscale;
               content: "\e907";
               position: absolute;
               font-size: 1.5rem;
               right: 0;
               top: 50%;
               transform: translateY(-50%) rotate(90deg);
               // border: tr(1) solid $color-accent;
               color: #828282;
               // border-radius: $rounded-circle;
               opacity: .8;
               transition: .4s ease-in-out;
           }

           .d-flex {
                height: max-content;
                gap: 0.625rem;
                background-color: #FFF5E5;
                color: #FFA722;
                padding: 0.25rem 0.5rem;
                border-radius: 6.25rem;

                img {
                    width: 1rem;
                    height: 1rem;
                }

                span {
                    flex: 1;
                    font-size: 0.75rem;
                }

                @media (max-width: 575.98px) {
                    width: max-content;
                    margin-top: 0.5rem;
                }
           }
       }

       ._sub-head {
            margin-top: 1rem;
            border-top: 0.0625rem solid #E6E5E5;
            padding-top: 1rem;

            p {
                color: $color-base2;

                @media (max-width: 575.98px) {
                    font-size: 0.875rem;
                }
            }

            .thumbs {
                display: flex;
                gap: .5rem;
                margin-top: 1rem;
                flex-wrap: wrap;
                transform-origin: left bottom;
                transform: scale(0);
                max-height: 0;
                margin-bottom: -2rem;
                transition: all .2s ease-out;

                img {
                    height: 2.5rem;
                    border-radius: 0.5rem;
                    border: 0.25rem solid rgba($color-accent, .15);
                }
            }
       }

        &.collapsed {

            ._top {
                &:after {
                    transform: translateY(-50%) rotate(270deg);
                }
            }

            ._sub-head {
                .thumbs {
                    transform: scale(1);
                    max-height: 100px;
                    transition: all .4s ease-out;
                    margin-bottom: 0;
                }
            }

        }
   }

    ._body {
        padding: 0 1.5rem 1rem;

        article {
            border-bottom: none !important;

            * {

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @media (max-width: 575.98px) {
            padding: tr(24);
        }

        @media (max-width: 575.98px) {
            padding: tr(16);
        }
    }

    .steps {
        display: flex;
        
        &:not(:last-child) {
            margin-bottom: tr(24);
        }

        &__number {
            display: block;
            width: tr(32);
            height: tr(32);
            font-size: tr(16);
            color: white;
            background-color: $color-accent;
            border-radius: $rounded-circle;
            text-align: center;
            line-height: tr(32);
            margin-right: tr(16);
        }

        &__content {
            flex: 1;
            color: $color-base2;
            font-size: tr(16);
            line-height: tr(24);
            padding: tr(4) 0;
        }
    }
}

.pay-method-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 tr(16);
    border: tr(1) solid $color-gray;
    border-radius: $rounded-small;
    height: tr(48);
    color: $color-base2;
    transition: all .1s ease-in-out;

    @media (max-width: 575.98px) {
        padding: 0 tr(8);
    }

    ._left {
        display: flex;
        align-items: center;
        flex: 1;

        img {
            height: tr(24);
            margin-right: tr(12);

            @media (max-width: 575.98px) {
                margin-right: tr(8);
            }
        }

        span {
            height: tr(21);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }
    }

    ._right {
        width: tr(56);
        text-align: right;

        span {

        }

        i {
            position: relative;
            top: tr(3);
            font-size: tr(16);
        }
    }

    &:hover {
        color: $color-base;
        border-color: $color-primary;
        background-color: rgba($color-primary, .05);
    }
}

.pay-method-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: tr(1) solid #E6E5E5;
    border-radius: 0.5rem;
    // height: tr(56);
    color: $color-base2;
    transition: all .1s ease-in-out;

    ._left {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 1.5rem;

        @media (max-width: 575.98px) {
           gap: 1rem;
        }

        img {
            height: tr(27);
        }

        span {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            transition: all .2s ease-out;

            @media (max-width: 575.98px) {
                font-size: 0.875rem;
             }
        }
    }

    ._right {
        text-align: right;
        display: flex;
        align-items: center;
        transition: all .2s ease-out;
        opacity: 0;

        span {
            position: relative;
            width: tr(24);
            height: tr(24);
            display: inline-block;
            border-radius: $rounded-circle;
            background-color: $color-gray;
            transition: all .1s ease-in-out;

            i {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: transparent;
                transition: all .1s ease-in-out;
            }
        }
    }

    &:hover {
        color: $color-base2;
        border-color: $color-accent;
        background-color: rgba($color-accent, .05);

        ._right {
            opacity: .5;

            span {
                background-color: rgba($color-accent, .2);
            }
        }
    }
}

.pay-method {
    display: block;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    input[type="radio"] {
        display: none;

        &:checked + .pay-method-list {
            border-color: transparent;
            background-color: rgba($color-accent, .15);

            ._left {

                span {
                    color: $color-accent;
                }
            }

            ._right {
                opacity: 1;

                span {
                    background-color: $color-accent;
    
                    i {
                        color: white;
                    }
                }
            }
        }
    }
}