.topheader
    height: tr(284)
    position: relative
    background-position: center
    background-size: cover
    background-repeat: no-repeat

    @media (max-width: 991.98px)
        height: auto
        padding: tr(32) 0

    @media (max-width: 575.98px)
        padding: tr(24) 0

    .container
        height: 100%

    &::before
        top: 0
        content: ''
        display: block
        position: absolute
        background-image: url('../img/header-overlay.svg')
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        height: 100%
        width: 100%

    &__content
        position: relative
        z-index: 1
        color: white
        height: 100%
        display: flex
        align-items: center
        justify-content: space-between

        @media (max-width: 991.98px)
            flex-direction: column
            align-items: flex-start

        &__left

            &--end
                width: 100%
                max-width: 600px

                @media (min-width: 992px)
                    align-self: flex-end

            .h3, .link-btn
                margin-bottom: tr(8)

            .link-btn
                margin-left: 1rem

                @media (max-width: 767.98px)
                    font-size: 0.75rem
                    height: 2rem
                    line-height: 1.75rem
            
            .input-pack
                margin-top: tr(28)

                @media (min-width: 992px)
                    margin-bottom: tr(28)

                .input
                    height: tr(40)

        &__right
            width: tr(336)

            @media (max-width: 991.98px)
                padding-top: tr(32)
                display: flex
                width: auto

            @media (max-width: 767.98px)
                display: block
                padding-top: tr(24)
        
    &__stats
        display: flex
        margin-right: tr(32)

        @media (min-width: 992px)
            &:not(:last-child)
                margin-bottom: tr(40)
                margin-right: 0

        @media (max-width: 767.98px)
            &:not(:last-child)
                margin-bottom: tr(24)

        &__thumb
            margin-right: tr(24)
            width: tr(72)
            height: tr(72)
            display: flex
            align-items: center
            justify-content: center
            border-radius: $rounded-circle
            background-color: $color-accent

            i
                font-size: tr(36)
                color: white

            @media (max-width: 767.98px)
                width: tr(56)
                height: tr(56)

                i
                    font-size: tr(28)

        &__desc
            flex: 1
            display: flex
            flex-direction: column
            justify-content: center

            &__title
                font-size: tr(18)
                margin-bottom: tr(8)

            .h5
                line-height: 1