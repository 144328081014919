.group {
    padding: tr(64) 0 tr(84);

    .container {
        .sc-header {
            margin-bottom: tr(24);
        }
    }

    .group-lists {
        position: relative;
        overflow: hidden !important;

        @media (max-width: 991.98px) {
            overflow: visible !important;
        }

        .swiper-slide {
            padding: tr(8) 0 tr(40);

            a {
                display: block;
                padding: tr(24);
                color: $color-base;
                border-radius: $rounded-small;
                border: tr(1) solid $color-gray;

                img {
                    width: 100%;
                }

            }
        }

        .campaign-pagination {
            justify-content: center;
        }
    }

    @media (max-width:991.98px) {
        padding: tr(54) 0;

        .container {

            &::before,
            &::after {
                content: unset;
            }
        }
    }
    
    @media (max-width:767.98px) {
        padding: tr(48) 0 tr(56);
    }
    
    @media (max-width:575.98px) {
        padding: tr(32) 0 tr(48);
    }
}