.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    border-bottom: tr(1) solid $color-gray-light;
    // box-shadow: $shadow;
    z-index: 101;
    &__wrapper {
        display: flex;
        height: $nav-height;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @media (max-width: 991.98px) {
            height: $nav-height-sm;
        }

        &__left {
            display: flex;
            align-items: center;
        }

        &__right {
            display: flex;
            align-items: center;
        }
    }

    &__logo {
        a {
            display: inline-block;
            
            img {
                height: tr(40);

                @media (max-width: 991.98px) {
                    height: tr(32);
                }
            }
        }
    }

    &__backlink {
        display: flex;
        align-items: center;
        color: $color-base;
        transition: all .2s ease-in-out;

        &:hover {
            color: $color-base;
            opacity: .6;
        }

        i {
            font-size: tr(24);
        }

        span {
            margin-left: tr(10);

            @media (max-width: 991.98px) {
                display: none;
            }
        }
    }

    .hide-from-chat {
        transition: all .2s ease-in-out .2s;

        &__on-mobile {

            &._hide {
                @media (max-width: 991.98px) {
                    opacity: 0;
                    transition: all .2s ease-in-out;
                }
            }
        }

        &__on-desktop {

            &._hide {
                @media (min-width: 992px) {
                    opacity: 0;
                    transition: all .2s ease-in-out;
                }
            }
        }
    }

    &__links-main {
        display: none;
        // margin-left: auto;

        > li {

            > a {
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {
                    opacity: 1 !important;
                    color: $color-base;
                }
            }

            &.navbar__profile {

                i {
                    position: relative;
                    left: tr(-30);
                }

                .navbar__profile__dropdown {
                    right: inherit;
                    max-width: max-content;
                    min-width: tr(180);

                    ul {
                        li {
                            display: block;

                            a {
                                margin-right: 0;
                                // display: block;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
            }
        }

        &:hover {
            
            > li {

                > a {
                    opacity: .6;
                }
            }
        }

        @media (min-width: 992px) {
            display: inline-block;
        }

        li {
            display: inline-block;
            a {
                font-weight: $fw-normal;
                display: inline-block;
                margin-right: tr(24);
                color: $color-base;
                text-decoration: none;

                &.fc-secondary {
                    font-weight: $fw-bold;
                }

                // @media (max-width: 1199.98px) {
                //     font-size: tr(12);
                //     margin-right: tr(15);
                // }
            }

            span {
                margin: tr(24);
            }

            @media (max-width:991.98px) {
                display: none;
            }
        }
    }

    &__search-btn {
        width: tr(20);
        height: tr(20);
        border: none;
        z-index: 99;
        outline: none !important;
        cursor: pointer;

        i {
            font-size: tr(20);
            // position: relative;
            // top: -.2rem;
            // left: -.2rem;
        }
    }

    &__search {
        flex:1;
        padding: 0 tr(24);
        position: relative;
        right: tr(0);
        // margin-left: auto;

        .navbar__profile {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: tr(56);

            @media (max-width: 991.98px) {
                display: flex !important;
            }

            i {
                right: tr(-14);
                left: unset;
            }

            &__dropdown {
                right: tr(-24);
            }

            &._active {

                i {
                    transform: rotate(-180deg) translateY(tr(6));
                }

                .navbar__profile {
                    &__dropdown {
                        top: tr(32);
                    }
                }
            }
            
        }

        @media (min-width: 992px) {
            max-width: tr(528);
            right: tr(138);
        }

        // @media (min-width: 1284px) {
        //     max-width: tr(528);
        // }

        // @media (max-width: 991.98px) {
            position: absolute;
            padding: 0;
            width: 100%;
            opacity: 0;
            top: -40%;
            // top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            // transition: top .3s ease-out, opacity 0s ease-out .2s;
            transition: all .4s $cbzr;

            &._show {
                top: 100%;
                opacity: 1;
                top: 50%;

            // transition: top .3s ease-out, opacity 0s ease-out .2s;
                transition: all .4s $cbzr;
            }
        // }

        i {
            color: $color-base;

            @media (max-width: 991.98px) {
                left: tr(10);
            }
        }

        input {
            height: tr(40);
            border-radius: $rounded-small;
            box-shadow: unset;
            background-color: $color-gray-light;
            border-color: $color-gray-light;
            padding-right: tr(124);

            &::-webkit-input-placeholder { // Edge 
                color: $color-base2;
            }
                
            &:-ms-input-placeholder { // Internet Explorer 10-11
                color: $color-base2;
            }
                
            &::placeholder {
                color: $color-base2;
            }

            @media (max-width: 991.98px) {
                padding-left: tr(32);
                // padding-right: tr(32);
            }
        }
    }

    &__button {

        &--register-btn {
            width: tr(124);

            @media (max-width:991.98px) {
                display: none !important;
            }
        }

        &--drawer-btn {
            width: tr(40);

            @media (min-width:992px) {
                display: none !important;
            }
        }
    }

    &__profile {
        @extend .noselect;
        // margin-left: -2rem;
        // margin-right: -3.6rem;
        // padding: 0 4.2rem 0 tr(24);
        display: flex;
        position: relative;
        cursor: pointer;
        align-items: center;

        // width: 103px;

        .avatar {
            transition: all .2s ease-in-out;
        }

        i {
            margin-left: tr(12);
            font-size: tr(12);
            transition: all .2s ease-out;
        }

        &::after {
            display: none;
            font-family: 'Custom-Icon-by-Ryaanst' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e907";
            right: tr(16);
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            transition: all .4s $cbzr;
        }

        &__name {
            font-size: tr(16);
        }

        &__dropdown {
            position: absolute;
            right: 0;
            top: 0;
            width: tr(224);
            margin: 0 -.1rem;
            max-height: 0;
            background-color: white;
            border: tr(1) solid $color-gray-light;
            border-radius: $rounded-small;
            box-shadow: $shadow10;
            opacity: 0;
            overflow: hidden;
            z-index: -2;
            transition: all .4s $cbzr;

            ul {
                padding: tr(8);

                li {
                    border-radius: $rounded-small;
                    transition: all .2s ease-in-out;

                    a,
                    label {
                        cursor: pointer;
                        display: block;
                        color: $color-base;
                        padding: tr(8);
                        transition: all .2s ease-in-out;
                    }

                    &:hover {
                        background-color: rgba($color-accent, .05);
                        
                        a {
                            color: $color-accent;
                        }
                    }
                }
            }
        }

        &._active {

            .avatar__sm-s {
                border: tr(2) solid $color-gray;
                transition: all .2s ease-in-out;
            }

            i {
                transform: rotate(-180deg);
            }

            &::after {
                transform: translateY(-50%) rotate(-180deg);
            }

            .navbar__profile__dropdown {
                opacity: 1;
                max-height: tr(240);
                top: tr(52);
            }
        }

        &--lang {

            ._lt {
                position: relative;
                left: -18px;
                top: 1px;
                padding-right: 5px;
                font-weight: bold;
            }

            &::after {
                display: inherit;
            }

            .avatar {
                margin-right: tr(24);
            }

            .navbar__profile__dropdown {
                right: 1.7rem;
                width: 17rem;

                .avatar {
                    margin-right: tr(16);
                }

                li {
                    &._selected {

                        a {
                            font-weight: $fw-bold;
                            color: $color-primary;
                        }
                    }
                }
            }

            &._active {

                .navbar__profile__dropdown {
                    top: 52px;
                }
            }
        }

        @media (max-width:991.98px) {
            display: none !important;

            &--lang {
                display: inherit !important;
            }
        }
    }

    &--logged {

        .navbar__search {

            @media (min-width: 992px) {
                right: tr(58) !important;
            }
        }
    }

    &--no-menu {
        .navbar__logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

// Category Menu Mobile
.category-menu {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    z-index: 999;
    transition: all .4s $cbzr;

    &__wrapper {
        background-color: white;
        border: tr(1) solid $color-gray-light;
        border-radius: $rounded-small;
        box-shadow: $shadow10;
    }

    ul {
        padding: tr(8);

        li {
            border-radius: $rounded-small;
            transition: all .2s ease-in-out;

            a {
                display: block;
                color: $color-base;
                padding: tr(8);
                transition: all .2s ease-in-out;
            }

            &:hover {
                background-color: rgba($color-secondary, .05);
                
                a {
                    color: $color-secondary;
                }
            }
        }
    }

    &._active {
        opacity: 1;
        max-height: tr(400);
        bottom: 7rem;
    }
}

// Drawer Menu Mobile
.drawer-menu {
    position: fixed;
    padding: tr(24) 0 tr(80);
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 998;
    transition: all .2s;
    overflow: hidden;
    overflow-y: scroll;

    .container {
        position: relative;
    }

    .drawer-float {
        position: sticky;
        bottom: tr(80);
        left: 0;
        width: 100%;
    }

    &__loreg {
        padding: 0 0 tr(24);
        border-bottom: $border-thin;

        .container {

            p {
                margin-bottom: tr(24);
                opacity: .8;
            }
    
            > div {
                display: flex;
                margin-left: tr(-4);
                margin-right: tr(-4);
        
                > div {
                    flex: auto;
                    padding-left: tr(4);
                    padding-right: tr(4);
                }
            }
        }
    }

    &__logged {
        padding: 0 0 tr(24);
        border-bottom: $border-thin;
        // background-image: url(../img/header-bg-lite.svg);
        // background-repeat: no-repeat;
        // background-size: auto 100%;
        // background-position: right top -25px;

        .container {

            > div {
                display: flex;
                align-items: center;
            }
        }

        &__user {
            margin-left: tr(8);

            span {
                color: $color-base2;
                font-size: tr(12);
                font-weight: $fw-normal;
            }
    
            div {
                font-family: $font-family2;
                font-size: tr(16);
                font-weight: $fw-bold;
            }
        }


        .container {

            p {
                margin-bottom: tr(24);
                opacity: .8;
            }
    
            > div {
                display: flex;
                margin-left: tr(-4);
                margin-right: tr(-4);
        
                > div {
                    flex: auto;
                    padding-left: tr(4);
                    padding-right: tr(4);
                }
            }
        }
    }

    &__links {
        padding: tr(16) 0;

        &:first-child {
            padding-top: 0;
        }

        &:not(:last-child) {
            border-bottom: $border-thin;
        }

        .container {

            @include navlink-icon;
        }
    }

    &._active {
        right: 0;
        opacity: 1;
        transform: scale(1);
    }

    ._bottom {
        margin-top: tr(16);
    }

    ._sns {
        text-align: center;
        margin-top: tr(20);

        p {
            margin-bottom: tr(10);
            font-size: tr(14);
        }

        div {

            a {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: tr(12);
                }

                i {
                    font-size: tr(24);
                }

                &:hover {
                    opacity: .6;
                }
            }
        }
    }

    &__title {
        font-family: $font-family2;
        font-weight: $fw-medium;
        font-size: tr(16);
        margin-bottom: tr(8);
        line-height: tr(28);
        color: $color-primary;
    }

    &__media {
        margin-top: tr(32);
        display: flex;
        justify-content: center;
        
        // p {
        //     display: block;
        // }

        a {
            width: tr(40);
            height: tr(40);
            border-radius: $rounded-circle;
            background-color: $color-gray-light;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s ease-in-out;

            &:not(:last-child) {
                margin-right: tr(24);
            }

            i {
                font-size: tr(20);
                color: $color-secondary;
            }

            &:hover {
                background-color: rgba($color-secondary, .2);
            }
        }
    }
}

.loreg-wrapper {
    padding-top:9.6rem;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height:100%;
    background-color: white;
    z-index: 99;
    // display: none;
    transform: scale(0);
    opacity: 0;
    transition: all .2s;
    overflow: scroll;

    #loginSection,
    #registerSection {
        display: none;
    }
    
    .container {
        height: 100%;
    }

    nav {
        display: flex;
        justify-content: flex-end;
        height: tr(80);

        .loreg-wrapper__close {
            background-color: transparent;
            border: 0;
            font-size: tr(24);
            color: $color-primary;
            outline: none;
        }
    }

    ._main {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        height: calc(100% - tr(80));

        &--login {
            display: none;

            &._active {
                display: flex;
            }
        }

        &--register {
            display: none;

            &._active {
                display: flex;
            }
        }
    }

    &__links {
        display: flex;
        justify-content: space-between;
        font-size: tr(10);
        margin-bottom: tr(32);

        div {

            a {
                text-decoration: none;
            }

            &:first-child {

                a {
                    color: $color-primary;
                }
            }

            &:last-child {

                a {
                    color: mix(white, $color-base, 60%);
                    margin-left: tr(24);
                }
            }
        }
    }

    &._active {
        display: block;
        transform: scale(1);
        opacity: 1;
    }

    .input-card {
        
        h3 {
            margin-bottom: tr(40);
        }

        ._or {
            text-align: center;
            margin: tr(24) 0 tr(8);
            opacity: .8;
        }

        &__label {
            margin: 0;
            margin-top: tr(24);
        }
    }
}

.navbar-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px -2px 32px 0px rgba(79, 104, 114, 0.08);
    z-index: 999;
    height: tr(62);
    background-color: white;
    border-radius: $rounded-xlarge $rounded-xlarge 0 0;
    transition: all .2s ease-out;

    &.-hide {
        transform: translateY(64px);
    }

    @media (min-width: 992px) {
        display: none;
    }

    .container {
        height: 100%;
    }

    &__wrapper {
        height: 100%;
    }

    &__menu {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-around;

        li {
            position: relative;

            a {
                cursor: pointer;
                display: block;
                text-align: center;
                color: $color-base2;
                transform: all .2s ease-in-out;

                i {
                    font-size: tr(22);
                    display: block;
                }

                > span {
                    font-size: tr(10);
                }
            }

            &._current {
                
                a {
                    color: $color-accent !important;
                }
            }

            &._notcurrent {
                
                a {
                    color: $color-base2 !important;
                }
            }

            > span {
                display: none;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;

                &._focus {
                    display: block;
                }
            }
        }
    }
}