.sc-ziswaf {
    @extend .sc-bg;
    // padding-top: tr(40);
    padding-bottom: tr(80);

    @media (max-width: 991.98px) {
        padding-bottom: tr(0);
    }
    
    // @media (max-width: 575.98px) {
    //     // padding-top: tr(24);
    //     padding-bottom: tr(0);
    // }

    .tab-wrapper {
        margin: tr(-24) tr(-40) tr(24);
        border-bottom: tr(1) solid $color-gray;

        @media (max-width: 575.98px) {
            margin: tr(-24) tr(-24) tr(24);
        }
    }
}

._g-stats {
    margin-top: 8px;

    .stats-card {
        padding: tr(8);
        border-radius: $rounded-small;
        background-color: white;
        border: 1px solid $color-gray-light;
        // box-shadow: $shadow;
        
        ._box {
            background-color: $color-tertiary;
            width: 1.8rem;
            height: 1.8rem;
            border-radius: $rounded-circle;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            .rck {
                font-size: 0.875rem;
                color: white;
            }
        }

        ._val {
            font-weight: bold;
            font-family: $font-family2;
            color: $color-base;
            font-size: 1rem;
            line-height: 1;
            margin-top: 0.875rem;
        }

        ._title {
            display: block;
            font-size: 10px;
            line-height: 1;
            margin-top: 6px;
            color: $color-base2;
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }
}