.badges {
    padding: tr(12) tr(24);
    display: flex;
    align-items: center;
    width: max-content;
    border-radius: $rounded-full;
    color: white;
    // border: tr(1) solid;

    &--small {
        padding: 0 tr(8);
        font-size: tr(14);
        height: tr(32);
        line-height: tr(30);
    }

    @media (max-width: 767.98px) {
        padding: tr(8) tr(12);

        &--small {
            padding: 0 tr(8);
        }
    }

    i {
        margin-right: tr(8);
    }

    &--secondary {
        background-color: $color-accent;
        border-color: $color-secondary;
    }

    &--success {
        background-color: $color-success;
        border-color: $color-success;
    }

    &--warning {
        background-color: $color-warning;
        border-color: $color-warning;
    }

    &--error {
        background-color: $color-error;
        border-color: $color-error;
    }

    &--info {
        background-color: $color-info;
        border-color: $color-info;
    }

    &--gray {
        background-color: $color-gray;
        border-color: $color-gray;
    }

    &--outline {
        background-color: transparent;

        &.badges {

            &--success {
                color: $color-success;
                background-color: rgba($color-success, .2);
            }
        
            &--warning {
                color: $color-warning;
                background-color: rgba($color-warning, .2);
            }
        
            &--error {
                color: $color-error;
                background-color: rgba($color-error, .2);
            }
        
            &--info {
                color: $color-info;
                background-color: rgba($color-info, .2);
            }
        }
    }
}