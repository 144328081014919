.donatur-card {
    border-bottom: $border-thin;
    // box-shadow: $shadow20;
    padding: tr(16) 0;

    &--large {
        padding: tr(20) 0;
    }
    
    &__flex {
        display: flex;

        &--aside {
            // flex-direction: row-reverse;

            // .donatur-card {
            //     &__info {
            //         margin-left: 0 !important;
            //     }
            // }
        }
    }

    &__info {
        flex: 1;
        margin-left: tr(16);
        transform: translateY(tr(-4));
        
        &__nom {
            font-size: tr(16);
            font-weight: $fw-bold;
        }

        &__name {
            font-family: $font-family2;
            font-size: tr(18);
            font-weight: $fw-bold;
        }

        &__date {
            font-size: tr(14);
        }

        &__comment {
    
            p {
                line-height: tr(24);
            }
        }
    }
}