// Float background
%float-bg {
    position:relative;
    z-index: 1;
    
    &::after {
        position:absolute;
        display:block;
        content: '';
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: -1;
    }
}

@mixin navlink-icon {
    li {
        a {
            display: block;
            margin: 0 tr(-16);
            padding: tr(8) tr(16);
            // height: tr(32);
            position: relative;
            color: $color-base;
            opacity: .8;
            text-decoration: none;

            .akf {
                font-size: tr(24);
                margin-right: tr(16);
            }

            span {
                font-size: tr(16);
                display: inline-block;
            }
        }

        &._active {

            a {
                color: $color-primary;
                opacity: 1;
                font-weight: $fw-bold;
            }
        }
    }
}

// Error Pages
.error {
    min-height: calc(100vh - 3.5625rem);

    .text-center {
        max-width: 25rem;
        padding: 2rem 0;
    }

    img {
        width: 50%;

        @media (min-width: 992px) {
            width: 75%;
        }
    }

    .h1 {
        background: linear-gradient(90deg, #389ED9 1.35%, #B1CF1F 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    a {
        margin-top: 1.5rem;
        width: 11.25rem;
    }

    @media (min-width: 992px) {
        min-height: calc(100vh - 4.0625rem);
    }
}