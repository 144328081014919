.Program {

    @import 'components/program-head';
    @import 'components/program-body';

    &--custom-bg {
        background-size: cover;
        background-position: center center;

        .program-head,
        .program-body {
            background-color: unset;

        }
    }
}