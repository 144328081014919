.mitra {
    padding: tr(80) 0 0;

    .container {
        .sc-header {
            margin-bottom: tr(24);
        }
    }

    .mitra-lists {
        position: relative;
        overflow: hidden !important;

        @media (max-width: 991.98px) {
            overflow: visible !important;
        }

        .swiper-slide {
            padding: tr(8) 0 tr(40);

            a {
                display: flex;
                text-align: center;
                flex-direction: column;
                align-items: center;
                padding: tr(40) 0 tr(24);
                color: $color-base;
                border-radius: $rounded-small;
                border: tr(1) solid $color-gray;

                img {
                    width: max-content;
                    height: tr(60);
                    margin-bottom: tr(32);
                }

                span {
                    font-size: tr(14);
                    font-weight: bold;
                }
            }
        }

        .campaign-pagination {
            justify-content: center;
        }
    }

    @media (max-width:991.98px) {
        padding: tr(54) 0;

        .container {

            &::before,
            &::after {
                content: unset;
            }
        }
    }
    
    @media (max-width:767.98px) {
        padding: tr(48) 0 0;
    }
    
    @media (max-width:575.98px) {
        padding: tr(32) 0 0;
    }
}