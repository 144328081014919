.program-detail-card {
    height: 100%;
    border: tr(1) solid $color-gray;
    border-radius: $rounded-large;
    // box-shadow: $shadow10;
    background-color: white;
    overflow: hidden;
    display: block;
    padding: tr(24);

    @media (max-width: 991.98px) {
        height: auto;
    }

    @media (max-width: 767.98px) {
        padding: tr(24) tr(16);
    }

    ._title {
        
        @media (min-width: 992px) {
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            height: 2.5rem;
        }

        @media (min-width: 1284px) {
            height: 6rem;
            -webkit-line-clamp: 2;
        }

    }

    &__type {
        color: $color-secondary;
        margin-bottom: tr(16);
        display: inline-block;
    }

    &__title-wrapper {
        margin-bottom: tr(16);
        position: relative;

            &::after {
                margin: 0 tr(-9);
                transform: translateY(tr(-9));
                padding: tr(8);
                display: inline-block;
                content: attr(full-title);
                position: absolute;
                top: 0;
                left: 0;
                width: calc(100% + tr(16));
                font-size: tr(18);
                line-height: 1.5;
                font-weight: bold;
                border-radius: $rounded-small;
                box-shadow: $shadow40;
                background-color: mix($color-primary, white, 4%);
                border: tr(1) solid transparent;
                opacity: 0;
                max-height: tr(64);
                overflow: hidden;
                transition: all .2s 0s ease-in-out;
            }

        &:hover {

            &::after {
                max-height: tr(200);
                opacity: 1;

                transition: all .2s .6s ease-in-out;
            }
        }
    }

    &__title {
        font-size: tr(18);
        line-height: 1.5;
        height: tr(54);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    &__time {
        margin-bottom: tr(16);
        color: $color-base2;
    }

    &__target {
        display: flex;
        justify-content: space-between;
    }

    .progress {
        margin-bottom: tr(12);
    }

    &__share {
        // margin-top: tr(24);
        text-align: center;

        ul { 
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: -1rem;

            @media (max-width: 767.98px) {
                margin-top: -0.75rem;
                max-width: 13.375rem;
                margin-left: auto;
                margin-right: auto;
            }

            li {

                margin: tr(16) tr(8) 0;

                @media (max-width: 767.98px) {
                    margin: tr(12) tr(6) 0;
                }

                a {
                    height: tr(40);
                    width: tr(40);
                    border-radius: $rounded-circle;
                    border: tr(1) solid;
                    display: inline-block;
                    font-size: tr(22);
                    line-height: tr(40);
                    border-color: transparent;
                    background-color: transparent;
                    color: $color-secondary;
                    transition: all .2s ease-in-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $color-base2;

                    > svg {
                        height: 18px;
                    }

                    @media (max-width: 767.98px) {
                        height: tr(36);
                        width: tr(36);
                        font-size: tr(20);

                        > svg {
                            height: 15px;
                        }
                    }

                    // &._fb {
                    //     color: $color-fb;
                    //     border-color: $color-fb;
                    // }

                    // &._wa {
                    //     color: $color-wa;
                    //     border-color: $color-wa;
                    // }

                    // &._tw {
                    //     color: $color-tw;
                    //     border-color: $color-tw;
                    // }
                    
                    // &._ig {
                    //     color: #E1306C;
                    //     border-color: #E1306C;
                    // }
                    
                    // &._tt {
                    //     color: #000;
                    //     border-color: #000;
                    // }

                    // &._yt {
                    //     color: #FF0000;
                    //     border-color: #FF0000;
                    // }

                    // &._sh {
                    //     color: $color-primary;
                    //     border-color: $color-primary;
                    // }

                    &:hover {
                        background-color: rgba($color-base2, .1);
                    }
                }
            }
        }
    }

    // .link-btn {

    //     @media (max-width: 991.98px) {
    //         position: fixed;
    //         bottom: tr(86);
    //         left: calc(50% - tr(32));
    //         transform: translateX(-50%);
    //         z-index: 99;
    //         width: tr(690-64);
    //     }

    //     @media (max-width: 767.98px) {
    //         width: tr(510-64);
    //     }
        
    //     @media (max-width: 575.98px) {
    //         left: calc(50% - tr(30));
    //         width: calc(100% - tr(32+56));
    //     }
    // }
}