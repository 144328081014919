.input-card {
    padding: tr(40);
    border-radius: $rounded-small;
    background-color: white;
    // border: tr(1) solid $color-gray-light;
    border-radius: $rounded-small;
    box-shadow: $shadow-gray;
    
    &--style2 {
        border: unset;
        padding: tr(64) tr(44);
        border-radius: $rounded-large;

        
    }

    @media (max-width: 575.98px) {
        padding: tr(24) tr(16);
    }

    &__main-img {
        height: tr(22);
        margin-bottom: tr(20);
    }

    &__program {
        display: flex;
        // align-items: center;
        margin-bottom: tr(24);

        @media (max-width: 767.98px) {
            &._thumb {
                display: block;
            }
        }

        img {
            // height: tr(112);
            width: tr(123);
            // width: 100%;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: $rounded-small;
        }

        ._back-anchor {
            margin-right: tr(8);
            margin-top: tr(3);
            
            a {
                transition: all .2s ease-in-out;

                i {
                    font-size: tr(22);
                    color: $color-base;
                }

                &:hover {
                    opacity: .8;
                }
            }
        }

        &__info {
            flex: 1;
            margin-left: tr(16);
            // margin-top: tr(16);

            ._title {
                font-family: $font-family2;
                font-size: tr(24);
                line-height: 1.3;
                font-weight: $fw-bold;
                margin-bottom: tr(8);
            }

            ._target {
                display: flex;

                > * {

                    span {
                        color: $color-base2;
                        margin-bottom: tr(4);
                        display: block;
                    }

                    p {
                        font-size: tr(16);
                    }
                }

                ._fund {

                    p {
                        font-weight: $fw-bold;
                        color: $color-accent;
                    }
                }

                ._time {
                    flex: 1;
                    text-align: right;

                    p {
                        color: $color-base;
                        font-weight: $fw-bold;
                    }
                }
            }
        }

        @media (max-width:767.98px) {
            // display: block;

            img {
                width: 100%;
                // height: tr(240);
                aspect-ratio: 1/1;
                margin-bottom: tr(16);
            }

            &__info {
                margin-left: unset;
                // margin-top: unset;
            }
        }

        // @media (max-width:767.98px) {

        //     img {
        //         height: tr(160);
        //     }
        // }
    }

    .sc-header {
        
        h1 {
            display: flex;

            ._back-anchor {
                margin-right: tr(16);
                font-size: tr(24);
                
                a {
                    color: $color-base;
                }
            }

            @media (min-width:576px) {
                line-height: tr(64);
            }
        }
    }

    // &__title {
    //     color: $color-primary;
    //     margin-bottom: tr(24);
    // }

    &__subtitle {
        font-size: tr(16);
        line-height: tr(24);
        display: block;
        color: $color-base2;
        margin-bottom: tr(24);
    }

    &__normsub {
        display: block;
        margin-bottom: tr(8);
        font-size: tr(16);
        font-weight: $fw-bold;
    }

    &__smallsub {
        display: block;
        color: $color-base2;
        margin-bottom: tr(8);
    }

    &__divsub {
        margin-bottom: tr(16);
    }

    &__total {
        font-size: tr(24);
        color: $color-base2;
        font-weight: $fw-bold;
    }

    &__small {
        font-size: tr(12);
        margin-bottom: tr(24);
    }

    ._error {
        font-size: tr(12);
        color: $color-error;
        margin-top: tr(8);
    }

    &__label {
        font-size: tr(16);

        a {
            color: $color-accent;
            text-decoration: none;
        }
    }

    .cb,
    .rb {
        margin-bottom: tr(32);
    }

    .tab-wrapper {
        margin: 0 tr(-40) tr(24);
    }

    #regular-target {
        display: none;
    }

    &--v2 {
        padding: 0;
        border-radius: 1rem;

        &__head {
            padding: 1.5rem;
            display: flex;
            justify-content: space-between;
        }

        &__body {
            padding: 1.5rem;

            ._box {
                background-color: rgba($color-accent, .1);
                border-radius: 0.5rem;
                padding: 1.5rem;
                color: $color-accent;
                margin-bottom: 1rem;
            }
        }

        &__step-pos {
            color: $color-accent;
            font-weight: medium;
            font-size: 0.875rem;
        }

        &__step-bar {
            background-color: rgba($color-accent, .1);

            div {
                width: 0;
                transition: all .2s ease-out;
                height: 0.25rem;
                background-color: $color-accent;
            }
        }

        &.-success {
            max-width: 34.6875rem;
            margin: 0 auto;
            padding-top: 3.5rem;
            padding-bottom: 3.5rem;
            border-bottom: 0.5rem solid $color-accent;

            img {
                width: 7.5rem;
                margin-bottom: 1.5rem;

                @media (max-width: 575.98px) {
                    width: 6rem;
                 }
            }

            h1 {
                margin-bottom: 0.75rem;
            }
        }
    }
}

.fundraiser {
    background-image: url('../img/pattern-islamic.svg');
    max-width: tr(808);
    margin: 0 auto;

    
    &__inner {
        max-width: tr(492);
        margin-left: auto;
        margin-right: auto;
        padding: tr(24) 0;
    }

    .pattern-icon {
        width: tr(167);
        height: tr(167);

        i {
            font-size: tr(100);
        }

        @media (max-width: 991.98px) {
            width: tr(88);
            height: tr(88);

            i {
                font-size: tr(48);
            }
        }
    }

    #link {
        display: block;
        background-color: $color-gray-light;
        font-size: tr(18);
        padding: tr(12) tr(18);
        border-radius: $rounded-small;
        margin-bottom: tr(16);
        word-break: break-all;
    }
}

.schedule-picker-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.schedule-picker {
    .button {
        cursor: pointer;
        user-select: none;
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.875rem;
        margin-bottom: 1rem;

        // label {

        // }

        .button {
            color: $color-accent;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            border: 0;

            // span {
                
            // }

            // i {
            //     font-size: 1.25rem;
            // }
        }
    }

    &__body {
        .button {
            border: 0.0625rem solid #E1E1E1;
            padding: 1rem;
            border-radius: 0.375rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $color-accent;

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            i {
                font-size: 0.75rem;
                transform: rotate(180deg);
                transition: all .2s ease-out;
            }
        }

        &__time-pick {
            padding: 1rem 0;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1.5rem;

            @media (max-width: 575.98px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;
            }

            label {

                div {
                    padding: 0.5rem;
                    text-align: center;
                    border-radius: 0.375rem;
                    background-color: #F6F6F6;
                    font-size: 1.25rem;
                    font-weight: 600;
                    cursor: pointer;

                    span {
                        font-size: 1rem;
                    }
                }

                input {
                    display: none;

                    &:checked {
                        + div {
                            background-color: rgba($color-accent, .1);
                            color: $color-accent;
                        }
                    }

                }
            }
        }
    }
    &--active {
        .schedule-picker__body {

            .button {

                i {
                    transform: rotate(0deg);
                }
            }
        }
    }

}