.program-card {
    border: tr(1) solid $color-gray-light;
    border-radius: $rounded-small;
    // box-shadow: $shadow;
    background-color: white;
    overflow: hidden;
    display: block;
    color: $color-base;
    box-shadow: $shadow-blue;
    backface-visibility: hidden;
    transition: all .2s ease-in-out;
    
    &:hover {
        text-decoration: none;

        @media (min-width:992px) {
            // box-shadow: $shadow20;
            transform: translateY(tr(-4)) scale(1.001);
            // transform: scale(1.004) translateY(tr(-3));
        }
    }

    &:active {
        @media (min-width:992px) {
            box-shadow: $shadow10;
            // transform: scale(1) translateY(0);
            transition: all .1s ease-in-out;
        }
    }

    &__img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;

        // @media (max-width: 575.98px) {
        //     height: 50vw;
        // }
    }

    &__description {
        padding: tr(16) tr(16) tr(26);

        @media (max-width: 575.98px) {
            padding: tr(14) tr(14) tr(24);
            display: flex;
            flex-direction: column;
        }

        &__category {
            color: $color-primary;
            margin-bottom: tr(4);
        }

        &__title {
            font-family: $font-family2;
            font-size: tr(18);
            font-weight: $fw-bold;
            margin-bottom: tr(16);
            color: $color-base;
            height: tr(54);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        &__target {
            display: flex;
            justify-content: space-between;
            margin-bottom: tr(18);
            color: $color-base;
            font-size: tr(16);

            // @media (max-width: 575.98px) {
            //     font-size: tr(14);
            //     order: 4;
            //     margin-top: tr(18);
            //     margin-bottom: 0 !important;
            // }

            > * {

                span {
                    color: $color-base2;
                    font-size: tr(14);
                    margin-bottom: tr(4);
                    display: block;

                    @media (max-width: 575.98px) {
                        font-size: tr(12);
                    }
                }
            }

            &__fund {

                p {
                    color: $color-accent;
                    font-weight: $fw-bold;
                }
            }

            &__time {
                text-align: right;

                p {
                    color: $color-accent;
                    font-weight: $fw-bold;
                }
            }

            @media (max-width:375px) {
                margin-bottom: tr(12);

                > * {

                    span {
                        font-size: tr(10);
                    }
                }

                &__fund {

                    p {
                        font-size: tr(10);
                    }
                }
    
                &__time {
                    p {
                        font-size: tr(10);
                    }
                }
            }
        }
    }

    // .progress {
    //     margin-bottom: tr(10);
    // }


    &--ver {

        @media (max-width:991.98px) {
            display: flex;

            .program-card {
                &__img {
                    width: tr(116);
                    height: tr(118);
                }
        
                &__description {
                    padding: tr(12);
                    flex: 1;
        
                    .label-category {
                        margin-bottom: 0;
                    }
        
                    &__title {
                        margin-bottom: tr(16);
                        font-size: tr(12);
                        height: tr(36);
                    }

                    &__target {
                        margin-bottom: tr(1);
                        > * span {
                            font-size: tr(10);


                        }

                        &__fund {
                            p {
                                font-size: tr(12);
                            }
                        }

                        &__time {
                            display: none;
                        }
                    }
                }
        
                .progress {
                    margin-bottom: unset;
                }
        
                .link-btn {
                    display: none;
                }
            }
    
        }
    
        // @media (max-width:575.98px) {
            
        //     .program-card {
        //         &__img {
        //             width: tr(120);
        //             height: tr(120);
        //         }

        //         &__description {

        //             .label-category {
        //                 line-height: 1;
        //             }

        //             .progress {
        //                 height: tr(2);

        //                 .progress-bar {

        //                     &::before {
        //                         height: tr(6);
        //                         width: tr(6);
        //                         border: none;
        //                     }
        //                 }
        //             }

        //             &__title {
        //                 margin-bottom: tr(16);
        //                 font-size: tr(12);
        //                 margin-top: tr(4);
        //             }

        //             &__target {
        //                 margin-top: tr(10);

        //                 > * {

        //                     span {
        //                         font-size: tr(10);
        //                     }
        //                 }

        //                 > * {

        //                     p {
        //                         font-size: tr(12);
        //                     }
        //                 }
        //             }
        //         }
        //     }

        // }
    
        // @media (max-width:375px) {
            
        //     .program-card {
        //         &__img {
        //             width: tr(120);
        //             height: tr(120);
        //         }
        
        //         &__description {
        
        //             .label-category {
        //                 font-size: tr(12);
        //                 margin-bottom: tr(2)
        //             }
            
        //             &__title {
        //                 height: tr(380);
        //                 font-size: tr(12);
        //             }
        //         }
        //     }
        // }
    
        // @media (max-width:314px) {
        //     position: relative;
        //     height: tr(148) !important;
    
        //     .program-card {
        //         &__img {
        //             position: absolute;
        //             margin: 0;
        //             width: 100%;
        //             max-width: 100%;
        //             height: 100%;
        //             object-fit: cover;
        //             opacity: 0.2;
        //         }
        //     }
        // }
    }

}