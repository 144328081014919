.article-card {
    color: $color-base;
    display: block;

    &:hover {
        color: inherit;
        text-decoration: none;
    }

    &__wrapper {
        border-radius: $rounded-small;
        overflow: hidden;
        color: $color-base;
        box-shadow: $shadow-blue;
        border: tr(1) solid $color-gray-light;
        background-color: white;
        transition: all .2s ease-in-out;

        &:hover {
    
            @media (min-width:992px) {
                transform: translateY(tr(-3));
            }
        }
    
        &:active {
            @media (min-width:992px) {
                // box-shadow: $shadow10;
                transition: all .1s ease-in-out;
            }
        }
    }

    &__img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;

        // @media (max-width: 1199.98px) {
        //     height: tr(200);
        // }

        @media (max-width: 767.98px) {
            border-radius: $rounded-large;
        }
    }

    &__description {
        padding: tr(24);

        @media (max-width: 767.98px) {
            padding: tr(16) tr(16) tr(24);
        }

        p {
            font-size: tr(14);
            color: $color-accent;
            margin-bottom: tr(4);

            @media (max-width: 767.98px) {
                font-weight: bold;
                font-family: $font-family2;
            }
        }

        &__title {
            margin-bottom: tr(16);
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            line-height: 1.25;

            @media (max-width: 767.98px) {
                font-size: tr(18);
            }
        }

        span {
            color: $color-base2;
            font-size: tr(14);

            @media (max-width: 767.98px) {
                font-size: tr(12);
            }
        }

        // .label-category {
        //     margin-bottom: tr(16);
        // }

        // &__entry {
        //     color: $color-base2;
        //     margin-bottom: tr(16);
        //     height: tr(72);
        //     overflow: hidden;
        //     display: -webkit-box;
        //     -webkit-line-clamp: 3;
        //     -webkit-box-orient: vertical;
        //     text-overflow: ellipsis;
        // }

        // &__btn {
        //     margin-left: auto;
        // }

        // @media (max-width:991.98px) {
        //     flex: 1;
        // }
    }
}
