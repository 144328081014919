.amazing-box
    &__header
        width: 100%
        height: 31rem

        @media (max-width: 991.98px)
            height: 50rem

        @media (max-width: 767.98px)
            height: 42rem
        
        @media (max-width: 565.98px)
            height: 32rem

    &__main
        position: relative
        width: 100%
        padding: tr(40) 0 tr(168)


        @media (min-width: 992px)
            .grid
                gap: 2rem

        @media (max-width: 991.98px)
            padding: 3.375rem 0

        @media (max-width: 767.98px)
            padding: 3rem 0

        @media (max-width: 575.98px)
            padding: 1rem 0

        


        &__left
            ._thumb
                aspect-ratio: 174/149
                object-fit: cover
                width: 100%
                border-radius: $rounded-xlarge
                margin-bottom: tr(40)

                @media (max-width: 991.98px)
                    margin-bottom: tr(32)
                    aspect-ratio: 1/1

                @media (max-width: 575.98px)
                    margin-bottom: tr(24)

        &__right
            .fitur-lite-card
                margin-bottom: tr(32)

            .nav-tabs
                margin-bottom: tr(16)
                margin-left: tr(-8)
                margin-right: tr(-8)
                border-bottom: 0

                .nav-item
                    padding-left: tr(8)
                    padding-right: tr(8)

                    .link-btn
                        &.active
                            background-color: $color-secondary
                            color: white !important

        .share-post
            border-top: tr(1) solid $color-gray-light
