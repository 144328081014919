.plan-card
    position: relative
    display: block
    // height: tr(368)
    border-radius: $rounded-xlarge
    padding: tr(32)
    display: flex
    flex-direction: column
    justify-content: flex-end
    color: white
    overflow: hidden
    transition: all .8s ease-out

    @media (max-width: 991.98px)
        height: 12rem

        &.-square
            height: tr(320)

    @media (max-width:767.98px)
        height: 9.75rem
        border-radius: $rounded-large
        padding: tr(24)

        &.-square
            height: tr(268)
        

    &--center
        justify-content: center
        align-items: center

    > *
        position: relative
        z-index: 1

    .badges
        position: absolute
        top: tr(32)
        left: tr(32)

        &--sm
            top: 1rem
            left: 1rem

        @media (max-width:991.98px)
            .h5
                font-size: tr(18)

        @media (max-width:767.98px)
            top: tr(24)
            left: tr(24)

            &--sm
                top: .75rem
                left: .75rem

            .h5
                font-size: tr(14)

    p
        &:last-child
            overflow: hidden
            display: -webkit-box
            -webkit-line-clamp: 2
            -webkit-box-orient: vertical
            text-overflow: ellipsis

    ._desc
        width: tr(464)

        @media (max-width: 991.98px)
            -webkit-line-clamp: 3 !important
            width: 100%

    .h6
        @media (max-width:575.98px)
            font-size: tr(12)
            font-weight: $fw-normal
            font-family: $font-family

    .h5.fc-secondary
        @media (max-width: 991.98px)
            font-size: tr(14)
            margin: tr(8) 0 tr(4) !important

    p.fs-m-2
        @media (max-width: 767.98px)
            font-size: tr(14)

    .h3
        line-height: 1.2

        @media (max-width:991.98px)
            font-size: tr(24)
        
        @media (max-width:767.98px)
            display: -webkit-box
            font-size: tr(24)
            -webkit-line-clamp: 1
            -webkit-box-orient: vertical
            text-overflow: ellipsis
            line-height: 1.25
            overflow: hidden
            height: auto
        
        @media (max-width:575.98px)
            font-size: tr(18)

    &::before
        display: block
        content: ''
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 0
        background-position: bottom left
        background-size: cover
        background-image: var(--bg-img)
        transition: all .8s ease-out

    &::after
        display: block
        content: ''
        position: absolute
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.8) 11.29%, rgba(255, 255, 255, 0) 89.31%)
        background-blend-mode: multiply
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 0

    &--small
        height: 100%
        padding: tr(24)

        .badges
            top: tr(24)
            left: tr(24)

    &:hover
        color: white

        &::before
            transform: scale(1.1)