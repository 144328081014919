.floating-contact {
    margin-left: auto;
    position: fixed;
    bottom: tr(24);
    left: 0;
    width: 100%;
    z-index: 999;

    @media (max-width: 991.98px) {
        bottom: tr(86);
        width: tr(48);
        right: 0;
    }

    .container {
        display: flex;
        justify-content: flex-end;
        position: relative;
    }

    .dummy-wa {
        position: absolute;
        right: tr(16);
        width: tr(360);
        box-shadow: 0 0 tr(32) rgba($color-base, .3);
        border-radius: $rounded-large;
        overflow: hidden;
        bottom: tr(0);
        opacity: 0;
        visibility: hidden;
        transition: all .6s $cbzr;

        @media (max-width: 565.98px) {
            // width: calc(100vw - tr(16));
            width: 100vw;
            border-radius: 0;
            bottom: tr(-140);
            right: tr(0);
        }

        &__header {
            background-color: #075e54;
            color: white;
            padding: tr(16);
            font-size: tr(14);
        }
        
        &__close {
            position: absolute;
            top: tr(16);
            right: tr(16);
            border: none;
            background-color: transparent;
            color: rgba(white, .4);
            transition: all .2s ease-out;

            &:hover {
                color: rgba(white, .8);
            }
        }

        &__body {
            padding: tr(16);
            height: tr(320);
            background-color: #ece5dd;

            @media (max-width: 565.98px) {
                height: calc(100vh - tr(105+53));
            }

            ._ballon {
                position: relative;
                background-color: white;
                border-radius: tr(12);
                padding: tr(8) tr(16);
                width: max-content;
                max-width: 80%;
                font-size: tr(14);
                box-shadow: $shadow20;

                i {
                    font-size: tr(48);
                    position: absolute;
                    left: tr(-8);
                    top: tr(-17);
                    color: white;
                }
            }
        }

        &__footer {
            background-color: white;
            position: relative;

            input {
                width: 100%;
                padding: tr(16);
                padding-right: tr(48);
                border: none;
                outline: none !important;
                font-size: tr(14);
            }

            button {
                position: absolute;
                right: tr(16);
                top: 50%;
                transform: translateY(-50%);
                border: none;
                background-color: transparent;
                color: rgba($color-base2, .6);
                outline: none !important;
                transition: all .2s ease-out;

                &:hover {
                    color: $color-base2;
                }
            }
        }

        &._show {
            bottom: tr(56);
            opacity: 1;
            visibility: visible;

            @media (max-width: 565.98px) {
                bottom: tr(-86);
            }
        }
    }

    &__btn {
        height: tr(40);
        width: tr(180);
        border-radius: $rounded-full;
        background-color: $color-wa;
        box-shadow: $shadow-gray;
        color: white;
        border: none;
        transition: all .2s ease-out;
        outline: none !important;

        @media (max-width: 991.98px) {
            height: tr(48);
            width: tr(48);
        }

        span {
            font-size: tr(14);
            margin-right: tr(8);

            @media (max-width: 991.98px) {
                display: none;
            }
        }

        &:hover {
            opacity: .8;
        }
    }
}


.footer {
    position: relative;
    color: white;
    overflow: hidden;
    background-image: url('../img/bg-footer.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    @media (max-width: 991.98px) {
        height: tr(62);
        border-top: none;
        opacity: 0;
        display: none;

        &::after {
            background-image: none;
        }
    }

    @media (max-width: 767.98px) {
        height: tr(32);
    }

    &__left {
        width: tr(306);
        margin-right: tr(106);

        ._top {

            img {
                height: tr(48);
                margin-bottom: tr(23);
            }
        }

        // ._middle {
        //     max-width: 29.7rem;
        //     margin-top: tr(20);
        //     
        //     p {
        //         color: $color-gray;
        //         margin-bottom: tr(18);
        //     }
        // }

        ._bottom {

            p {
                margin-bottom: tr(16);
            }
        }
    }

    &__link-list {
        
        li  {
            font-size: tr(16);

            &:not(:last-child) {
                margin-bottom: tr(8);
            }

            a {
                color: inherit;
                line-height: tr(24);
                text-decoration: none;
                transition: all .2s ease-in-out;

                &:hover {
                    color: white;
                    opacity: .6;
                }
            }

            &._sns {
                margin-top: tr(20);

                p {
                    margin-bottom: tr(10);
                    font-size: tr(14);
                }

                div {

                    a {
                        display: inline-block;

                        &:not(:last-child) {
                            margin-right: tr(12);
                        }

                        i {
                            font-size: tr(24);
                        }

                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }
        }
    }

    &__info {
        margin-bottom: tr(16);
        display: flex;

        &__icon {
            margin-right: tr(18);

            i {
                font-size: tr(22);
            }
        }

        > * {
            line-height: tr(24);
        }
    }

    &__top {
        padding: tr(150) 0 tr(32);

        @media (max-width:767.98px) {
            padding: tr(40) 0 0;
        }

        > .row {

            > div {

                &:first-child {
                    @media (max-width:991.98px) {
                        margin-bottom: tr(48);
                    }
                }
            }
        }

        @media (max-width:991.98px) {
            display: none;
        }
    }

    &__bottom {
        background-color: transparent;
        font-size: tr(14);
        padding: tr(24) 0;

        @media (max-width:991.98px) {
            display: none;
        }
    }

    @media (max-width: 991.98px) {
        margin-top: unset;

        &__bottom {
            padding: tr(24) 0 tr(16);

            div {
                margin: 0 auto;
            }
        }
    }
}