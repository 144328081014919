.Article {

    .article-head {
        padding: tr(40) 0 0;
        height: tr(532);

        @media (max-width: 767.98px) {
            height: tr(400);
        }
    
        > * {
            margin-bottom: tr(-96);
        }
    
        ._title {
            margin-bottom: tr(40);

            .h4 {
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
            }
        }
    }

    .article-body {
        background-color: white;
        padding-bottom: tr(80);
        position: relative;
        padding: 0 0 tr(80);

        &__top {
            top: tr(-302);
            margin-bottom: tr(-302);
            position: relative;

            @media (max-width: 991.98px) {
                top: tr(-258);
                margin-bottom: tr(-258);
            }

            @media (max-width: 767.98px) {
                top: tr(-136);
                margin-bottom: tr(-136);
            }

            @media (max-width: 575.98px) {
                top: tr(-162);
                margin-bottom: tr(-162);
            }
        }

        &__img {
            border-radius: $rounded-xlarge;
            width: 100%;
            // height: tr(395);
            overflow: hidden;
            margin-bottom: tr(40);

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            @media (max-width: 991.98px) {
                height: tr(480);
            }
    
            @media (max-width: 767.98px) {
                border-radius: $rounded-large;
                height: tr(320);
            }
    
            @media (max-width: 575.98px) {
                height: 60vw;
            }
        }
    
        &__main {
            background-color: white;

            article {
                padding-bottom: tr(32);
                border-bottom: tr(1) solid $color-gray-light;
            }
        }
    
        // ._wrapper {
        //     padding: tr(48) 0 0;
    
        //     @media (max-width: 767.98px) {
        //         padding: tr(24) 0 0;
        //     }
    
        //     ._top {
        //         display: flex;
        //         justify-content: space-between;
        //         align-items: center;
        //         // padding-top: tr(16);
        //         padding-bottom: tr(32);
    
        //         h3 {
        //             padding-right: tr(16);
        //         }
    
        //         a {
        //             margin: 0;
        //         }
    
        //         @media (max-width: 767.98px) {
        //             flex-direction: column;
    
        //             h3 {
        //                 padding-right: 0;
        //                 padding-bottom: tr(16);
        //             }
        //         }
        //     }
        // }
        &__related {
            margin-top: tr(40);

            @media (max-width: 767.98px) {
                margin-top: tr(32);
            }

            @media (max-width: 767.98px) {
                margin-top: tr(24);
            }

            .h3 {
                margin-bottom: tr(24) !important;
            }

            .update-lists {
                position: relative;
        
                .swiper-slide {
                    padding: tr(8) 0 tr(24);

                    @media (max-width: 991.98px) {
                        width: tr(400);
                    }

                    @media (max-width: 767.98px) {
                        width: tr(320);
                    }

                    @media (max-width: 575.98px) {
                        width: tr(240);
                    }
                }
        
                .update-pagination {
                    justify-content: center;
                }
            }
        }
    }
}