.Mitra {
    .sc-mitra-list {
        @extend .sc-bg;
        padding: 0 0 tr(80);
    
        @media (max-width: 575.98px) {
            padding: 0 0 tr(40);
        }

        .mitra-list {
            display: flex;
            text-align: center;
            flex-direction: column;
            align-items: center;
            padding: tr(40) 0 tr(24);
            color: $color-base;
            border-radius: $rounded-small;
            border: tr(1) solid $color-gray-lightest;
            box-shadow: $shadow-blue;

            img {
                width: max-content;
                height: tr(60);
                margin-bottom: tr(32);
            }

            span {
                font-size: tr(14);
                font-weight: bold;
            }
        }

        .cta-card {
            margin-top: tr(80);

            @media (max-width: 991.98px) {
                margin-top: tr(40);
            }
        }
    }
}