.fitur-card {
    transition: all .2s ease-in-out;
    display: block;

    img {
        width: tr(116);
        height: tr(116);
        margin-bottom: tr(26);
        // border-radius: $rounded-circle;
        transition: all .2s ease-in-out;

        @media (max-width: 991.98px) {
            width: tr(88);
            height: tr(88);
            margin-bottom: tr(16);
        }
        
        @media (max-width: 575.98px) {
            width: tr(64);
            height: tr(64);
            margin-bottom: tr(12);
        }
    }

    &__title {
        color: $color-base;
        font-size: tr(24);
        font-weight: $fw-bold;
        margin-bottom: tr(28);
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 991.98px) {
            font-size: tr(20);
        }
    }

    &:hover {
        
        img {
            opacity: .6;
        }
    }
}

// Lite
.fitur-lite-card {
    padding: tr(24);
    // box-shadow: $shadow;
    border-radius: $rounded-small;
    background-color: white;
    box-shadow: $shadow-gray;
    display: flex;
    align-items: center;

    &__title {
        font-size: tr(24);
        font-weight: $fw-bold;
        display: flex;
        align-items: center;
    }

    &__icon {
        width: tr(72);
        height: tr(72);
        border-radius: $rounded-circle;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: tr(16);

        i {
            color: white;
            font-size: tr(36);
        }

        @media (max-width: 991.98px) {
            width: tr(48);
            height: tr(48);

            i {
                font-size: tr(24);
            }
        }
    }

    &__desc {

        ._title {
            font-size: tr(18);
            color: $color-base2;
            // font-weight: $fw-bold;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            margin-bottom: tr(12);
        }

        .h5 {
            line-height: 1;
        }
    }

    @media (max-width:991.98px) {
        padding: tr(10) tr(8);

        // &:first-child {
        //     margin-top: tr(30);
        // }

        &__title {

            img {
                margin-right: tr(16);
                width: tr(32) !important;
                height: tr(32);
            }
        }

        &__desc {

            ._title {
                font-size: tr(12);
            }

            ._value {
                font-size: tr(16);
            }
        }
    }

    &.-v2 {
        padding: 0;
        align-items: center;
        border:none;

        .fitur-lite-card {
            &__title {
                
                img {
                    width: tr(72);
                }
            }
        }
    }
}