.no-overflow {
    overflow: hidden;
}

.blur {
    filter: blur(tr(10));
}

.f {

    &color- {

        &warning {
            color: $color-warning;
        }
    }
}

.sc-header {
    margin-bottom: tr(24);

    p {
        font-size: tr(16);
        color: $color-base2;
        @media (max-width: 575.98px) {
            margin-top: tr(8);
        }
    }

    // &.-flex {
    //     display: flex;
    //     align-items: center;
        
    //     ._back-anchor {
    //         font-size: tr(22);
    //         margin-right: tr(24);

    //         a {
    //             color: $color-base;
    //             transition: all .2s ease-in-out;
                
    //             &:hover {
    //                 opacity: tr(8);
    //             }
    //         }
    //     }
    // }
}

[class^="pill-category"] {
    display: block;
    cursor: pointer;
    border: none;
    width: 100%;
    outline: none !important;

    ._wrap {
        background-color:$color-accent-light;
        padding: tr(12) tr(4);
        border-radius: $rounded-large;
        border: tr(1) solid $color-accent-light;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-accent;
        transition: all .2s ease-out;

        @media (max-width: 767.98px) {
            padding: tr(6) tr(4);
        }

        i {
            font-size: tr(24);
            margin-right: tr(8);
        }

        span {
            text-align: left;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

            @media (max-width: 767.98px) {
                font-size: tr(14);
            }
        }
    
        &:hover {
            background-color: mix($color-accent, white, 10%);
            border-color: mix($color-accent, white, 10%);
        }
    }

    &._selected {
        &:not(.pill-category-other) {
            cursor:initial;
        }

        ._wrap {
            background-color: $color-accent;
            border-color: $color-accent;
            color: white;
        }
    }
}

.backlink {
    display: inline-block;
    margin-bottom: tr(24);
    font-weight: $fw-bold;
    color: $color-secondary;
    font-weight: tr(16);

    i {
        margin-right: tr(14);
    }

    &:hover {
        color: $color-secondary;
    }
}

.topheader {

    .sc-header {
        display: flex;
        align-items: center;
        color: $color-base;
        font-size: tr(32);
        line-height: tr(24);
        margin-bottom: tr(40);

        @media (max-width: 575.98px) {
            margin-bottom: tr(24);
        }

        a {
            margin-right: tr(24);
            margin-bottom: tr(7);
            display: block;

            i {
                font-size: tr(22);
                color: $color-base;
            }
        }
        
        h1 {
            color: $color-base;
        }
    }
}

.filter {
    margin-bottom: tr(40);

    > .row {
        align-items: center;

        @media (max-width: 991.98px) {
            flex-direction:column-reverse;
        }
    }

    &__result {
        display: block;
        // margin-top: tr(24);
        font-size: tr(16);
        color: $color-base2;
    }

    @media (max-width: 991.98px) {

        .col-12 {
            
            &:first-child {
                margin-top: tr(16);
            }
        }
    }
}

// .sc-header {
//     margin-bottom: tr(24);

//     h1 {
//         margin-bottom: tr(8);
//     }

//     &__flex {
//         display: flex;
//         justify-content: space-between;

//         &__info {

//             p {
//                 font-size: tr(16);
//                 line-height: tr(24);
//                 color: $color-base2;
//             }

//             @media (max-width:991.98px) {
//                 max-width: 100%;
//                 flex: 1;
//                 padding-right: tr(48);
//             }

//             &--sort {
//                 max-width: 50%;
//             }
//         }

//         &__btn {
//             width: tr(160);
//         }

//         &__filter {
//             display: flex;

//             > * {
//                 width: tr(160);

//                 &:not(:last-child) {
//                     margin-right: tr(16);
//                 }
//             }
//         }
//     }

//     @media (max-width:767.98px) {
//         margin-bottom: tr(24);
        
//         h3 {
//             margin-bottom: tr(8);
//         }

//         &__flex {
//             display: none;

//             &__info {
//                 p {
//                     font-size: tr(14);
//                 }
//             }
    
//             &__btn {
//                 width: 100%;
//                 margin-top: tr(24);
//             }
//         }
//     }
// }

.sc-bg {
    background-color: white;
}

//
.slick-slide {
    outline: none !important;
}

.slick-prev,
.slick-next
{
    @extend .shadow40;
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 40px;
    height: 40px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background-color: $color-secondary;
    border-radius: $rounded-circle;
    transition: all 0.4s cubic-bezier(0, 0.36, 0.51, 2.15) 0s;

    // @media (max-width: 1199.98px) {
    //     top: inherit;
    //     bottom: tr(82);
    // }

    // @media (max-width: 575.98px) {
    //     bottom: tr(22);
    // }

}
.slick-prev:hover
.slick-next:hover
{
    color: transparent;
    outline: none;
    opacity: 1;
}

.slick-prev:hover
{
    transform: translate(-5px, -20px)
}

.slick-next:hover
{
    transform: translate(5px, -20px)
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'Custom-Icon-by-Ryaanst' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: tr(28);
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 7%;
    z-index: 99;
}
// [dir='rtl'] .slick-prev
// {
//     right: 7%;
//     left: auto;
// }
.slick-prev:before
{
    content: "\e903";
}
// [dir='rtl'] .slick-prev:before
// {
//     content: "\e903";
// }

.slick-next
{
    right: 7%;
    z-index: 99;
}
// [dir='rtl'] .slick-next
// {
//     right: auto;
//     left: 7%;
// }
.slick-next:before
{
    content: "\e904";
}
// [dir='rtl'] .slick-next:before
// {
//     content: "\e904";
// }

/* Dots */
.slick-dotted.slick-slider
{
    // margin-bottom: tr(18);

    @media (min-width:992px) {
        // margin-bottom: tr(32);
    }
}

.slick-dots
{
    position: absolute;
    bottom: 0;

    padding: 0 15px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;

    display: block;

    // width: 100%;
    margin: 0;

    list-style: none;

    // width: 100%;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media (min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }

    @media (min-width: 1284px) {
        max-width: 1224px;
    }

    @media (max-width: 1199.98px) {
        bottom: tr(16);
    }
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: tr(4);
    height: tr(4);
    margin: 0 tr(1);
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: tr(4);
    height: tr(4);

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    // font-family: 'slick';
    font-size: 6px;
    line-height: tr(4);

    position: absolute;
    top: 0;
    left: 0;

    width: tr(4);
    height: tr(4);

    content: '';
    text-align: center;
    background-color: $color-gray;
    border-radius: tr(100);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    transition: all .2s;
}

li.slick-active {
    width: tr(14);

    // @media (max-width: 991.98px) {
    //     width: tr(16);
    // }
}

.slick-dots li.slick-active button:before
{
    background-color: $color-secondary;
    width: tr(14);

    // @media (max-width: 991.98px) {
    //     width: tr(16);
    // }
}

.slick-left-align > .slick-list > .slick-track {    
    margin-left:0;
}

.modal {
    background-color: rgba($color-base, .75);
    backdrop-filter: blur(.5rem);
}

.modal-dialog {
    
    .modal-content {
        padding: 2.5rem 1.5rem !important;
        border: none;
        border-radius: 1rem;
        border-bottom: 0.5rem solid $color-accent;

        &__head {
            padding-bottom: 1.5rem;
            margin-bottom: 2rem;

            .d-flex {
                button {
                    border: 0;
                    font-size: 1rem;
                    color: $color-base2;
                    transition: all .2s ease-out;

                    &:hover {
                        opacity: .75;
                    }
                }
            }
            p {
                margin-top: 0.75rem;

                @media (max-width: 575.98px) {
                    font-size: 0.875rem;
                }
            }
        }

        &__body {
            
        }
    }
}

//Breadcrumb
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: tr(24);

    > * {
        font-size: tr(12);
        line-height: tr(19);
    }

    li {

        &:not(:last-child) {

            &::after {
                font-family: 'Custom-Icon-by-Ryaanst' !important;
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e904";

                display: inline-block;
                transform: translateY(tr(1));
                margin: 0 tr(6);
                font-size: tr(13);
            }
        }

        a {
            color: $color-secondary;
        }

        span {
            color: $color-base2;
        }
    }
}

// Share Post
.share-post {
    display: flex;
    align-items: center;
    
    span {
        margin-left: auto;
        font-size: tr(18);
        margin-right: tr(8);
    }

    &__icon {
        margin-right: auto;
        display: flex;
        justify-content: center;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: tr(40);
            height: tr(40);
            color: white;
            border-radius: $rounded-circle;
            margin-left: tr(16);
            transition: all .2s ease-in-out;

            i {
                color: white;
                font-size: tr(22);
            }

            &._fb {
                background-color: $color-fb;
            }

            &._tw {
                background-color: $color-tw;
            }

            &._wa {
                background-color: $color-wa;
            }

            &:hover {
                opacity: .8;
            }
        }

        // &--fb {
        //     background-color: $color-fb;
        // }

        // &--wa {
        //     background-color: $color-wa;
        // }

        // &--tw {
        //     background-color: $color-tw;
        // }
    }

    @media (max-width:575.98px) {
        display: block;

        span {
            display: block;
            text-align: center;
            margin-right: unset;
            margin-bottom: tr(16);
        }

        &__icon {
            text-align: center;

            a {

                &:first-child {
                    margin-left: unset;
                }
            }
        }
    }
}

._empty {
    text-align: center;
    padding: tr(24);
    margin: 0 auto;
    
    img {
        margin-bottom: tr(32);
    }
    
    p {
        font-size: tr(16);
        color: $color-base2;
        margin-bottom: tr(24);
    }
}

.btn-float-mobile {

    @media (max-width: 991.98px) {
        position: fixed;
        bottom: tr(86);
        left: calc(50% - tr(32));
        transform: translateX(-50%);
        z-index: 99;
        width: tr(690-64);
    }

    @media (max-width: 767.98px) {
        width: tr(510-64);
    }
    
    @media (max-width: 575.98px) {
        left: calc(50% - tr(30));
        width: calc(100% - tr(32+56));
    }
}

.btn-float-mobile-form {

    @media (max-width: 991.98px) {
        position: fixed;
        bottom: tr(86);
        transform: translateX(-50%);
        z-index: 99;
        left: calc(50%);
        width: 43rem;

        &.-float-form-small {
            width: 38rem;
        }
    }

    @media (max-width: 767.98px) {
        width: 31.75rem;

        &.-float-form-small {
            width: 26.75rem;
        }
    }
    
    @media (max-width: 575.98px) {
        width: calc(100% - 2rem);

        &.-float-form-small {
            width: calc(100% - 4rem);
        }
    }
}

.float-bottom-scroll {
    transition: all .2s ease-out;

    @media (max-width: 991.98px) {
        &.-active {
            bottom: 1rem;
            
            &.float-bottom-scroll--form {
                bottom: 3rem;
            }
        }
    }
    
    @media (max-width: 767.98px) {
        &.-active {
            
            &.float-bottom-scroll--form {
                bottom: 3rem;
            }
        }
    }
    
    @media (max-width: 565.98px) {
        &.-active {
            
            &.float-bottom-scroll--form {
                bottom: 2.75rem;
            }
        }
    }
}

.cursor-grab {
    cursor: grab;
}