.gradient-bg
    position: relative
    background-size: tr(800)
    background-position: left center
    background-image: url('../img/pattern-islamic.svg')

    &::after
        top: 0
        left: 0
        width: 100%
        height: 100%
        content: ''
        display: block
        position: absolute
        background: linear-gradient(270deg, #EDF8F0 32%, #ffffff00 100%)

    > *
        position: relative
        z-index: 1