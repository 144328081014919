.static-card {
    background-color: white;
    border-radius: $rounded-small;
    border: tr(1) solid $color-gray-light;
    box-shadow: $shadow10;
    padding: tr(24);

    h2 {
        margin-bottom: tr(16);
    }

    h3 {
        margin-bottom: tr(16);
    }

    p {
        font-size: tr(16);
        line-height: tr(24);
        color: $color-base2;

        &:not(:last-child) {
            margin-bottom: tr(24);
        }
    }

    ol,
    ul {
        margin-left: 20px;
        list-style: unset;

        li {
            font-size: tr(16);
            line-height: tr(24);
            color: $color-base2;
        }

        &:not(:last-child) {
            margin-bottom: tr(24);
        }
    }

    iframe {
        width: 100%;
        height: tr(320);
        border: none;
    }

    &__info {
        margin-top: tr(16);
        display: flex;
        color: $color-base2;

        &__icon {
            margin-right: tr(18);

            i {
                font-size: tr(22);
                color: $color-base;
            }
        }

        a {
            color: inherit;
        }

        > * {
            line-height: tr(24);
        }
    }
}