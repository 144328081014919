.Project {
    .project-body {
        background-color: white;
        padding-bottom: tr(80);
        position: relative;
        padding: 0 0 tr(80);

        @media (max-width: 991.98px) {
            padding: 0;
        }


        @media (max-width: 767.98px) {
            padding: 0;
        }

        &__thumb {
            height: tr(532);
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                
                @media (max-width: 991.98px) {
                    object-position: top left;
                }
            }

            &::after {
                top: 0;
                left: 0;
                display: block;
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(0deg, #323232 0%, rgba(255, 255, 255, 0) 100%);
            }

            @media (max-width: 767.98px) {
                height: tr(400);
            }
        }

        &__top {
            margin-top: tr(-100);
            position: relative;

            .badges {
                position: absolute;
                top: tr(-64);
            }
        }

        ._inner {
            background-color: white;
            border-radius: $rounded-small;
            box-shadow: $shadow-gray;
            padding: tr(40);
            
            ._stats {
                margin-top: 8px;

                .stats-card {
                    padding: tr(8);
                    border-radius: $rounded-small;
                    background-color: white;
                    border: 1px solid $color-gray-light;
                    // box-shadow: $shadow;
                    
                    ._box {
                        background-color: $color-tertiary;
                        width: 1.8rem;
                        height: 1.8rem;
                        border-radius: $rounded-circle;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;

                        .rck {
                            font-size: 0.875rem;
                            color: white;
                        }
                    }

                    ._val {
                        font-weight: bold;
                        font-family: $font-family2;
                        color: $color-base;
                        font-size: 1rem;
                        line-height: 1;
                        margin-top: 0.875rem;
                    }

                    ._title {
                        display: block;
                        font-size: 10px;
                        line-height: 1;
                        margin-top: 6px;
                        color: $color-base2;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                    }
                }
            }

            ._desc {
                margin-top: 1rem;

                @media (max-width: 991.98px) {
                    height: 5.125rem;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;

                    &.-show {
                        height: auto;
                        -webkit-line-clamp: unset;
                    }
                }
            }

            .desc-btn {
                display: none;
                padding: 0;
                border: 0;
                outline: none !important;
                color: $color-accent;
                font-weight: bold;
                color: $color-accent;
                @media (max-width: 991.98px) {
                    display: inherit;
                }
            }

            @media (max-width: 767.98px) {
                padding: tr(24);
            }
        }

        ._sns {
            margin-top: 24px;

            a {
                width: 40px;
                height: 40px;
                border: 1px solid $color-gray;
                border-radius: $rounded-circle;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-base2;
                margin: 0 6px;
                transition: all .2s ease-out;

                &:hover {
                    border-color: $color-accent;
                    color: $color-accent;
                }
            }
        }
    
        &__main {
            
        }

        &__aside {

        }

        &__related {
            margin-top: tr(144);

            @media (max-width: 767.98px) {
                margin-top: tr(80);
            }

            @media (max-width: 767.98px) {
                margin-top: tr(80);
            }

            .h3 {
                margin-bottom: tr(24) !important;
            }

            .update-lists {
                position: relative;
        
                .swiper-slide {
                    padding: tr(8) 0 tr(24);

                    @media (max-width: 991.98px) {
                        width: tr(400);
                    }

                    @media (max-width: 767.98px) {
                        width: tr(320);
                    }

                    @media (max-width: 575.98px) {
                        width: tr(240);
                    }
                }
        
                .update-pagination {
                    justify-content: center;
                }
            }
        }

        .title-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: tr(24);

            @media (max-width: 767.98px) {
                margin-bottom: tr(16);
            }
        }

        .testimoni {
            padding: tr(80) 0 0;
        
            .testimoni-lists {
                position: relative;
                overflow: hidden !important;
        
                @media (max-width: 991.98px) {
                    overflow: visible !important;
                }
        
                .swiper-slide {
                    padding: tr(8) 0 tr(16);

                    @media (max-width: 991.98px) {
                        width: tr(400);
                        padding-bottom: tr(32);
                    }

                    @media (max-width: 575.98px) {
                        width: tr(200);
                    }
        
                    .testimoni-card {
                        display: flex;
                        padding: tr(32) tr(24);
                        height: tr(204);
                        border-radius: $rounded-small;
                        box-shadow: $shadow-gray;

                        @media (max-width:991.98px) {
                            padding: tr(24) tr(16);
                            height: tr(162);
                        }

                        @media (max-width: 575.98px) {
                            flex-direction: column;
                            align-items: center;
                            height: auto;
                            height: tr(240);
                        }

                        img {
                            width: tr(72);
                            height: tr(72);
                            margin-right: tr(16);

                            @media (max-width:991.98px) {
                                width: tr(48);
                                height: tr(48);
                            }

                            @media (max-width: 575.98px) {
                                margin-right: 0;
                                margin-bottom: tr(16);
                            }
                        }

                        div {
                            flex: 1;

                            p {
                                font-size: tr(18);
                                color: $color-base2;
                                margin-bottom: tr(24);
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                text-overflow: ellipsis;

                                @media (max-width:991.98px) {
                                    font-size: tr(16);
                                    margin-bottom: tr(16);
                                }

                                @media (max-width: 575.98px) {
                                    font-size: tr(14);
                                    -webkit-line-clamp: 4;
                                }
                            }

                            span {
                                @media (max-width:991.98px) {
                                    font-size: tr(18);
                                }
                            }
                        }
                    }
                }
        
                .testimoni-pagination {
                    justify-content: center;
                }
            }
        
            @media (max-width:991.98px) {
                padding: tr(54) 0;
        
                .container {
        
                    &::before,
                    &::after {
                        content: unset;
                    }
                }
            }
            
            @media (max-width:767.98px) {
                padding: tr(48) 0 0;
            }
            
            @media (max-width:575.98px) {
                padding: tr(32) 0 0;
            }
        }

        .mitra {
            padding: tr(56) 0 0;
        
            .mitra-lists {
                position: relative;
                overflow: hidden !important;
        
                @media (max-width: 991.98px) {
                    overflow: visible !important;
                }
        
                .swiper-slide {
                    padding: tr(8) 0 tr(40);

                    @media (max-width: 991.98px) {
                        width: tr(180);
                    }
        
                    a {
                        display: flex;
                        text-align: center;
                        flex-direction: column;
                        align-items: center;
                        padding: tr(40) 0 tr(24);
                        color: $color-base;
                        border-radius: $rounded-small;
                        border: tr(1) solid $color-gray-lightest;

                        @media (max-width: 767.98px) {
                            padding: tr(24) 0 tr(16);
                            box-shadow: $shadow-gray;
                        }
        
                        img {
                            width: max-content;
                            height: tr(60);
                            margin-bottom: tr(32);

                            @media (max-width: 767.98px) {
                                height: tr(32);
                                margin-bottom: tr(16);
                            }
                        }
        
                        span {
                            font-size: tr(14);
                            font-weight: bold;
                        }
                    }
                }
        
                .mitra-pagination {
                    justify-content: center;
                }
            }
        
            @media (max-width:991.98px) {
                padding: tr(54) 0;
        
                .container {
        
                    &::before,
                    &::after {
                        content: unset;
                    }
                }
            }
            
            @media (max-width:767.98px) {
                padding: tr(48) 0 0;
            }
            
            @media (max-width:575.98px) {
                padding: tr(32) 0 0;
            }
        }

        .asatidz {
            padding: tr(56) 0 0;
        
            .asatidz-lists {
                position: relative;
                overflow: hidden !important;
        
                @media (max-width: 991.98px) {
                    overflow: visible !important;
                }
        
                .swiper-slide {
                    padding: tr(8) 0 tr(40);

                    @media (max-width: 991.98px) {
                        width: tr(180);
                    }

                    @media (max-width: 767.98px) {
                        width: tr(120);
                    }
        
                    .profile-card {

                        img {
                            height: tr(288);
                            border-radius: $rounded-small;
                            object-fit: cover;
                            margin-bottom: tr(16);
                            width: 100%;

                            @media (max-width: 1199.98px) {
                                height: tr(214);
                            }

                            @media (max-width: 991.98px) {
                                height: tr(180);
                            }

                            @media (max-width: 767.98px) {
                                height: tr(120);
                                margin-bottom: tr(8);
                            }
                        }

                        .h5 {
                            @media (max-width: 767.98px) {
                                font-size: tr(16);
                                margin-bottom: tr(16) !important;
                                height: tr(48);
                            }
                        }

                        &__sns {
                            display: flex;

                            a {
                                display: block;
                                color: $color-base2;
                                transition: all .2s ease-out;

                                &:not(:last-child) {
                                    margin-right: tr(24);

                                    @media (max-width: 767.98px) {
                                        margin-right: tr(16);
                                    }
                                }

                                i {
                                    font-size: tr(24);

                                    @media (max-width: 767.98px) {
                                        font-size: tr(18);
                                    }
    
                                }

                                &:hover {
                                    opacity: .6;
                                }
                            }
                        }
                    }
                }
        
                .asatidz-pagination {
                    justify-content: center;
                }
            }
        
            @media (max-width:991.98px) {
                padding: tr(54) 0;
        
                .container {
        
                    &::before,
                    &::after {
                        content: unset;
                    }
                }
            }
            
            @media (max-width:767.98px) {
                padding: tr(48) 0 0;
            }
            
            @media (max-width:575.98px) {
                padding: tr(32) 0 0;
            }
        }

        .cta {
            margin-top: tr(32);
        }
    }

    ._about {
        position: relative;
        top: tr(-72);
        margin-bottom: tr(-72);

        @media (max-width: 767.98px) {
            top: tr(-56);
            margin-bottom: tr(-56);
        }
    }

    ._about-aside {
        // padding-top: tr(24);
        @media (min-width: 992px) {
            padding-top: tr(24);
            padding-left: tr(80);
        }
    }

    iframe {
        width: 100%;
        height: tr(240);

        @media (max-width:767.98px) {
            height: tr(160);
        }
    }

    ._vision,
    ._mission {
        padding: tr(40);
        color: white;
        border-radius: $rounded-small;
        box-shadow: $shadow-gray;

        @media (max-width: 767.98px) {
            padding: tr(24);
        }

        .h4 {
            margin-bottom: tr(16);
            line-height: 1;

            @media (max-width: 767.98px) {
                margin-bottom: tr(8);
            }
        }
    }

    ._vision {
        background-color: $color-accent;
    }

    ._mission {
        background-color: $color-secondary;
    }
}