.selected-kajian {
    padding: tr(80) 0 tr(80);
    background-color: rgba($color-gray-light, .2);

    .container {
        .sc-header {
            margin-bottom: tr(24);
        }
    }

    .kajian-lists {
        display: grid;
        grid-template-columns: 1fr tr(392);
        gap: tr(24);

        &__main {
            // height: tr(784);
            display: grid;
            grid-template-rows: 1fr;
            gap: tr(16);
        }

        &__sub {
            height: calc(90vh - tr(100));
            display: grid;
            grid-template-rows: auto 1fr;
            gap: tr(24);
            height: 100%;
        }

        .plan-card.-square {
            aspect-ratio: 1/1;
            width: 100%;
        }
    }

    .kajian-lists-mob {
        position: relative;
        overflow: hidden !important;

        @media (max-width: 991.98px) {
            overflow: visible !important;
        }

        .swiper-slide {
            padding: tr(8) 0 tr(24);

            @media (max-width: 991.98px) {
                width: tr(320);
            }

            @media (max-width: 767.98px) {
                width: tr(280);
            }
        }

        .kajian-pagination {
            justify-content: center;
        }
    }

    @media (max-width:991.98px) {
        padding: tr(54) 0;

        .container {

            &::before,
            &::after {
                content: unset;
            }
        }
    }
    
    @media (max-width:767.98px) {
        padding: tr(24) 0 tr(24);
    }
}