.selected-program {
    padding: tr(92) 0 tr(80);
    background-image: url('../img/pattern-islamic.svg');

    .container {
        .sc-header {
            margin-bottom: tr(32);
        }
    }

    .program-lists {
        display: grid;
        grid-template-columns: 1fr tr(392);
        gap: tr(24);

        @media (max-width: 991.98px) {
            grid-template-columns: 1fr;
        }

        &__main {
            // height: tr(784);
            display: grid;
            grid-template-rows: 1fr 1fr;
            gap: tr(16);
        }

        &__sub {
            height: calc(90vh - tr(64));
            width: 100%;
            overflow: hidden !important;

            @media (max-width: 991.98px) {
                height: 19.875rem;
            }

            .swiper-wrapper {
                // padding: tr(16) 0;
                height: calc(100% + tr(16));
            }

            #subProgramBtnTop,
            #subProgramBtnBottom {
                display: block;
                width: 100%;
                position: absolute;
                z-index: 3;
                height: tr(56);
                display: flex;
                justify-content: center;
                transition: all .2s ease-out;

                &.swiper-button-disabled {
                    visibility: hidden;
                }

                button {
                    height: tr(32);
                    width: tr(32);
                    border: none;
                    border-radius: $rounded-circle;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: rotate(90deg);
                    transition: transform .1s ease-out;
                    
                    i {
                        font-size: tr(24);
                        color: $color-accent;
                    }

                    &:hover {
                        transform: rotate(90deg) scale(1.1);
                        opacity: .8;
                    }
                }
            }

            #subProgramBtnTop {
                background: linear-gradient(180deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 100%);
                top: -1px;

                button {
                }
            }

            #subProgramBtnBottom {
                background: linear-gradient(0deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 100%);
                bottom: -1px;
                align-items: flex-end;

                button {
                }
            }
        }
    }

    .program-lists-mob {
        position: relative;
        overflow: hidden !important;

        @media (max-width: 991.98px) {
            overflow: visible !important;
        }

        .swiper-slide {
            padding: tr(8) 0 tr(24);

            @media (max-width: 991.98px) {
                width: tr(320);
            }

            @media (max-width: 767.98px) {
                width: tr(280);
            }
        }

        .program-pagination {
            justify-content: center;
        }
    }

    @media (max-width:991.98px) {
        padding: tr(54) 0;

        .container {

            &::before,
            &::after {
                content: unset;
            }
        }
    }

    @media (max-width:991.98px) {
        padding: tr(54) 0;
    }
    
    @media (max-width:767.98px) {
        padding: tr(24) 0 tr(24);
    }
}