.program-card-ver {
    border: tr(1) solid $color-gray-light;
    border-radius: $rounded-small;
    box-shadow: $shadow;
    background-color: white;
    overflow: hidden;
    display: block;
    color: $color-base;
    backface-visibility: hidden;
    transition: all .2s ease-in-out;
    // backface-visibility: hidden;
    display: flex;
    
    &:hover {
        text-decoration: none;

        @media (min-width:992px) {
            box-shadow: $shadow20;
        }
    }

    &:active {
        @media (min-width:992px) {
            box-shadow: $shadow10;
            transition: all .1s ease-in-out;
        }
    }

    &__img {
        width: tr(392);
        height: tr(289);
        object-fit: cover;
    }

    &__description {
        padding: tr(16);
        flex: 1;

        &__title {
            font-family: $font-family2;
            font-size: tr(24);
            font-weight: $fw-bold;
            margin-bottom: tr(16);
            color: $color-base;
            height: tr(72);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        &__target {
            display: flex;
            justify-content: space-between;
            margin-bottom: tr(50);
            color: $color-base;
            font-size: tr(16);

            > * {

                span {
                    color: $color-gray;
                    font-size: tr(14);
                    margin-bottom: tr(4);
                    display: block;
                }
            }

            &__fund {

                p {
                    font-weight: $fw-bold;
                    color: $color-primary;
                }
            }

            &__time {
                text-align: right;
            }

            @media (max-width:375px) {
                margin-bottom: tr(34);

                > * {

                    span {
                        font-size: tr(10);
                    }
                }

                &__fund {

                    p {
                        font-size: tr(10);
                    }
                }
    
                &__time {
                    p {
                        font-size: tr(10);
                    }
                }
            }
        }
    }

    .progress {
        margin-bottom: tr(18);
    }

    @media (max-width:991.98px) {
        display: flex;

        &__img {
            width: tr(240);
            height: tr(175);
        }

        &__description {
            padding: tr(16);
            flex: 1;

            .label-category {
                margin-bottom: tr(36);
            }

            &__title {
                margin-bottom: tr(4);
                font-size: tr(14);
                height: tr(42);
            }

            &__target {
                font-size: tr(12);

                &__fund {
                    font-weight: bold;
                }

                // &__time {
                // }
            }
        }

        .progress {
            margin-bottom: unset;
        }

        .link-btn {
            display: none;
        }
    }

    @media (max-width:575.98px) {

        &__img {
            width: tr(160);
        }
    }

    @media (max-width:375px) {

        &__img {
            width: tr(120);
            height: tr(145);
        }

        &__description {

            .label-category {
                margin-bottom: tr(12);
            }
    
            &__title {
                height: tr(38);
                font-size: tr(13);
            }
        }
    }

    @media (max-width:314px) {
        position: relative;
        height: tr(148) !important;

        &__img {
            position: absolute;
            margin: 0;
            width: 100%;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.2;
        }
    }
}