.side-card {
    background-color: white;
    border-radius: $rounded-small;
    box-shadow: $shadow-gray;
    padding: tr(24);
    text-align: center;

    // .pattern-icon {
    //     width: tr(120);
    //     height: tr(120);
    //     font-size: tr(64);
    // }
}