.history-card {
    background-color: white;
    border-radius: $rounded-small;
    border: tr(1) solid $color-gray-lightest;
    box-shadow: $shadow-gray;
    overflow: hidden;
    position: relative;

    #codelink {
        display: none;
    }

    &__description {
        padding: tr(16);

        ._flex {
            display: flex;
            margin: 0 tr(-8);

            ._flex__list {
                padding: 0 tr(8);

                &._span {

                    > * {
                        padding-right: tr(16);
                    }
                }
            }

            @media (max-width: 1199.98px) {
                flex-direction: column;
            }
        }

        ._copy {
            position: absolute;
            right: tr(-8);
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 1199.98px) {
                top: tr(28);
            }
            
            @media (max-width: 767.98px) {
                top: unset;
                bottom: 0;
            }

            &::before {
                content: 'Tersalin';
            }

            &._copied {

                i {
                    right: tr(16);
                }

                &::before {
                    left: tr(-18);
                }
            }
        }

        &__cat {
            font-weight: $fw-bold;
            color: $color-accent;
            margin-bottom: tr(6);
        }

        &__title {
            color: $color-base;
            margin-bottom: tr(6);
            width: calc(100% - tr(138));
            line-height:tr(24);
            font-size: tr(16);
            font-weight: $fw-bold;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        &__nom,
        &__nom2 {
            font-family: $font-family2;
            font-weight: $fw-bold;
            font-weight: $fw-bold;
            color: $color-secondary;
            font-size: tr(18);
            margin-bottom: tr(6);
        }

        &__date,
        &__label2 {
            font-size:tr(12);
            color: $color-base2;
        }

        &__label2 {
            position: absolute;
            right: tr(16);
            top: tr(16);

            @media (max-width: 575.98px) {
                right: unset;
                top: unset;
                padding-top:tr(10);
            }
        }

        &__nom2 {
            position: absolute;
            right: tr(16);
            top: tr(40);

            @media (max-width: 575.98px) {
                right: unset;
                top: unset;
                padding-top: tr(26);
            }
        }

        a {
            color: $color-accent;

            &:hover,
            &:active {
                color: $color-accent;
            }
        }

        &__confirmation {
            position: absolute;
            right: tr(16);
            top: tr(104);
            font-size: tr(16);
            font-weight: $fw-bold;
            color: $color-gray;

            i {
                transform: translateY(tr(1));
                display: inline-block;
            }

            span {
                margin-left:tr(10);
            }
        }
    }

    .badges {
        position: absolute;
        right: tr(16);
        top: tr(44);
        justify-content: center;
        width: tr(126);
    }

    // @media (max-width:991.98px) {

    //     &__description {
    //         padding: tr(16);

    //         &__title {
    //             margin-bottom: tr(24);
    //         }
    //     }

    //     .badges {
    //         top: tr(16);
    //     }
    // }

    @media (max-width:767.98px) {

        &__description {

            &__title {
                width: unset;
            }
        }

        .badges {
            top: tr(16);
            right: tr(14);
        }
    }

    @media (max-width:575.98px) {

        &__description {
            padding-bottom: tr(64);

            &__title {
                margin-bottom: tr(8);
            }

            &__confirmation {
                top: unset;
                bottom:tr(32);
            }
        }

        &__img {
            height: tr(106);
            width: tr(126);
        }

        .badges {
            box-shadow: unset;
            left: 0;
            top: unset;
            bottom: 0;
            width: 100%;
            border-radius: unset;
        }
    }
}