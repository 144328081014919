@font-face {
  font-family: 'Custom-Icon-by-Ryaanst';
  src:  url('../fonts/Custom-Icon-by-Ryaanst.eot?quhey7');
  src:  url('../fonts/Custom-Icon-by-Ryaanst.eot?quhey7#iefix') format('embedded-opentype'),
    url('../fonts/Custom-Icon-by-Ryaanst.ttf?quhey7') format('truetype'),
    url('../fonts/Custom-Icon-by-Ryaanst.woff?quhey7') format('woff'),
    url('../fonts/Custom-Icon-by-Ryaanst.svg?quhey7#Custom-Icon-by-Ryaanst') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.rck {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Custom-Icon-by-Ryaanst' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ryd-donasi:before {
  content: "\e900";
}
.ryd-add:before {
  content: "\e901";
}
.ryd-add-2:before {
  content: "\e902";
}
.ryd-alumni:before {
  content: "\e903";
}
.ryd-amazing-box:before {
  content: "\e904";
}
.ryd-arrow-down:before {
  content: "\e905";
}
.ryd-arrow-next:before {
  content: "\e906";
}
.ryd-arrow-outline-left:before {
  content: "\e907";
}
.ryd-arrow-outline-right:before {
  content: "\e908";
}
.ryd-babe:before {
  content: "\e909";
}
.ryd-box:before {
  content: "\e90a";
}
.ryd-calculator:before {
  content: "\e90b";
}
.ryd-calendar:before {
  content: "\e90c";
}
.ryd-check:before {
  content: "\e90d";
}
.ryd-claim:before {
  content: "\e90e";
}
.ryd-confirmation:before {
  content: "\e90f";
}
.ryd-copy:before {
  content: "\e910";
}
.ryd-direction:before {
  content: "\e911";
}
.ryd-donasi-user:before {
  content: "\e912";
}
.ryd-donatur:before {
  content: "\e913";
}
.ryd-download:before {
  content: "\e914";
}
.ryd-edit:before {
  content: "\e915";
}
.ryd-email:before {
  content: "\e916";
}
.ryd-exchange:before {
  content: "\e917";
}
.ryd-eye:before {
  content: "\e918";
}
.ryd-eye-hide:before {
  content: "\e919";
}
.ryd-filter:before {
  content: "\e91a";
}
.ryd-fundraiser:before {
  content: "\e91b";
}
.ryd-group:before {
  content: "\e91c";
}
.ryd-home:before {
  content: "\e91d";
}
.ryd-infaq:before {
  content: "\e91e";
}
.ryd-kajian:before {
  content: "\e91f";
}
.ryd-live:before {
  content: "\e920";
}
.ryd-loading:before {
  content: "\e921";
}
.ryd-location:before {
  content: "\e922";
}
.ryd-message:before {
  content: "\e923";
}
.ryd-more:before {
  content: "\e924";
}
.ryd-mosque:before {
  content: "\e925";
}
.ryd-news:before {
  content: "\e926";
}
.ryd-office:before {
  content: "\e927";
}
.ryd-online:before {
  content: "\e928";
}
.ryd-overview:before {
  content: "\e929";
}
.ryd-phone:before {
  content: "\e92a";
}
.ryd-photo:before {
  content: "\e92b";
}
.ryd-program:before {
  content: "\e92c";
}
.ryd-remove:before {
  content: "\e92d";
}
.ryd-remove-round:before {
  content: "\e92e";
}
.ryd-riwayat:before {
  content: "\e92f";
}
.ryd-search:before {
  content: "\e930";
}
.ryd-sedekah:before {
  content: "\e931";
}
.ryd-setting:before {
  content: "\e932";
}
.ryd-shirt:before {
  content: "\e933";
}
.ryd-subtract:before {
  content: "\e934";
}
.ryd-time:before {
  content: "\e935";
}
.ryd-trash:before {
  content: "\e936";
}
.ryd-wa:before {
  content: "\e937";
}
.ryd-wakaf:before {
  content: "\e938";
}
.ryd-wakaf-1:before {
  content: "\e939";
}
.ryd-zakat:before {
  content: "\e93a";
}