.cb {
    height: tr(24);
    cursor: pointer;

    input[type="checkbox"] {
        display: none;
    }

    &__label {
        font-size: tr(16);
        margin-left: tr(8);
        transform: translateY(-3px);
        display: inline-block;
        
        span {
            opacity: .8;
        }

        // a {
        //     color: $color-accent;
        //     font-weight: $fw-bold;
        // }
    }

    &::before {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        display: inline-block;
        content: "\f00c";
        width: tr(24);
        height: tr(24);
        border-radius: $rounded-small;
        color: white;
        background-color: white;
        border: tr(1) solid mix($color-base, white, 20%);
        top: tr(-4);
        position: relative;

        font-size: tr(14);
        text-align: center;
        line-height: tr(22);
        transition: all .2s;
    }

    &--v2 {
        display: flex;
        align-items: center;

        > *:not(:last-child) {
            margin-right: tr(16);
        }

        &::before {
            display: none;
        }

        .cb__box {
            position: relative;
            width: tr(48);
            height: tr(28);

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                transition: all .2s ease-in-out;
            }

            &::before {
                top: 0;
                left: 0;
                height: tr(28);
                width: tr(48);
                border-radius: $rounded-full;
                background-color: $color-gray;
                border-color: $color-gray;
            }
    
            &::after {
                top: tr(2);
                left: tr(2);
                width: tr(24);
                height: tr(24);
                background-color: white;
                border-radius: $rounded-circle;
            }
        }

        .cb__label {
            margin-left: 0;
            transform: unset;
        }
    }

    &--checked {

        &::before {
            content: "\f00c";
            background-color: $color-accent;
            border: tr(1) solid $color-accent;
        }

        &.cb--v2 {

            .cb__box {
    
                &::before {
                    background-color: $color-accent;
                    border-color: $color-accent;
                }
        
                &::after {
                    left: tr(22);
                }
            }
        }
    }
}

.rb {
    height: tr(24);
    cursor: pointer;

    &__label {
        font-size: tr(16);
        margin-left: tr(8);
        transform: translateY(-10px);
        display: inline-block;
        
        span {
            opacity: .8;
        }
    }

    > div {
        display: inline-block;
        width: tr(24);
        height: tr(24);
        border-radius: $rounded-circle;
        color: white;
        background-color: white;
        border: tr(1) solid mix($color-base, white, 20%);
        top: tr(-4);
        position: relative;
        transition: all .2s;

        &::after {
            display: block;
            content: '';
            position: absolute;
            width: 76%;
            height: 76%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: $rounded-circle;
            background-color: white;
            transition: all .2s;
        }
    }

    input[type="radio"] {
        display: none;

        &:checked {

             + div {
                background-color: white;
                border: tr(1) solid $color-secondary;

                &::after {
                    background-color: $color-secondary;
                }
            }
        }
    }
}
  
[type="number"] {
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0;
    }
}

.pseuinput {
    cursor: pointer;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input,
.pseuinput {
    background-color: $color-gray-lightest;
    border: tr(1) solid $color-gray-lightest;
    border-radius: $rounded-small;
    height: tr(48);
    outline: none;
    width: 100%;
    padding: tr(12) tr(16);
    font-weight: $fw-normal;
    transition: all .2s ease-in-out;

    &--v2 {
        background-color: white;
        border-color: $color-gray
    }
    
    span {
        @extend .truncate;
        line-height: tr(48);
    }


    &::-webkit-input-placeholder { // Edge 
        color: $color-base2;
        transition: all .2s ease-in-out;
    }
        
    &:-ms-input-placeholder { // Internet Explorer 10-11
        color: $color-base2;
        transition: all .2s ease-in-out;
    }
        
    &::placeholder {
        color: $color-base2;
        transition: all .2s ease-in-out;
    }

    @media (min-width: 992px) {

        // &:hover {
        //     // border-color: $color-secondary !important;
        //     background-color: mix($color-base, white, 7%);
    
        //     &::-webkit-input-placeholder { // Edge 
        //         color: mix($color-base, white, 40%);
        //     }
                
        //     &:-ms-input-placeholder { // Internet Explorer 10-11
        //         color: mix($color-base, white, 40%);
        //     }
                
        //     &::placeholder {
        //         color: mix($color-base, white, 40%);
        //     }
        // }
    }


//     &:focus {
//         color: $color-secondary;
//         background-color: rgba($color-secondary, .2) !important;
// 
//         &::-webkit-input-placeholder { // Edge 
//             color: rgba($color-secondary, .4);
//         }
//             
//         &:-ms-input-placeholder { // Internet Explorer 10-11
//             color: rgba($color-secondary, .4);
//         }
//             
//         &::placeholder {
//             color: rgba($color-secondary, .4);
//         }
//     }
}

textarea {
    height: tr(140) !important;
}

input:disabled {
    background-color: $color-gray;
}


.input-pack {
    position: relative;

    &--distance {
        margin-bottom: tr(24);
    }

    &__label {
        display: block;
        margin-bottom: tr(4);
        color: $color-base;
    }

    &__desc {
        margin-top: tr(10);
        display: inline-block;
        color: $color-base2;
    }

    h3 {
        margin-bottom: tr(16) !important;
    }

    small {
        margin-top: tr(8);
        display: block;
        font-weight: bold;
    }

    &--error {

        input {
            color: $color-error;
            border-color: $color-error;
            background-color: mix($color-error, white, 10%);
        }

        small {
            color: $color-error;
        }
    }
}

.input-wrap {
    position: relative;

    i {
        position: absolute;
        right: tr(16);
        top: 50%;
        transform: translateY(-50%);
        font-size: tr(16);
        color: $color-base2;
        z-index: 1;
    }

    &--il {

        i {
            right: unset;
            left: tr(16);
        }

        .pseuinput,
        .input {
            padding-left: tr(40);
        }
    }

    &--ir {
        .pseuinput,
        .input {
            padding-right: tr(40);
        }
    }
}

.select-file {
    margin-bottom: tr(40);

    &__label {
        color: $color-base2;
        margin-bottom: tr(4);
    }

    label {
        margin-right: tr(16);

        input[type="file"] {
            display: none;
        }
    }
}

.img-upload {
    display: flex;
    align-items: center;

    img {
        margin-right: tr(16);
    }

    ._right {
        flex: 1;
    }

    &__label {
        display: block;
        margin-top: tr(8);
    }
}

// .options {
//     margin-left: tr(-12);
//     margin-right: tr(-12);
//     
//     > * {
//         padding-left: tr(12);
//         padding-right: tr(12);
//         margin-bottom: tr(24);
// 
//         button {
//             display: flex;
//             align-items: center;
//             justify-content: center;
// 
//             i {
//                 margin-right: tr(8);
//                 width: tr(20);
//                 height: tr(20);
//                 border-radius: 50%;
//                 background-color: $color-secondary;
//                 color: white;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//                 font-size: tr(12);
//             }
//             
//             span {
//                 font-weight: $fw-bold;
//                 line-height: 1;
//                 margin-top: tr(1);
//             }
//         }
//     }
// 
//     @media (max-width: 575.98px) {
//         margin-left: tr(-4);
//         margin-right: tr(-4);
//         
//         > * {
//             padding-left: tr(4);
//             padding-right: tr(4);
//             margin-bottom: tr(8);
// 
//             button {
// 
//                 i {
//                     margin-right: tr(4);
//                     width: tr(16);
//                     height: tr(16);
//                     border-radius: 50%;
//                     background-color: $color-secondary;
//                     color: white;
//                     display: flex;
//                     align-items: center;
//                     justify-content: center;
//                     font-size: tr(8);
//                 }
// 
//                 span {
//                     font-size: tr(10);
//                 }
//             }
//         }
//     }
// }

.options {
    margin-left: tr(-4);
    margin-right: tr(-4);
    
    > * {
        padding-left: tr(4);
        padding-right: tr(4);
        margin-bottom: tr(8);

        label {
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                width: 100%;
                height: tr(56);
                border-radius: $rounded-small;
                border: tr(1) solid $color-gray;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-gray;
                flex-direction: column;

                span {
                    font-size: tr(16);
                    font-weight: bold;
                    font-family: $font-family2;
                }

                small {
                    font-size: tr(8);
                    font-weight: normal;
                    margin-top: tr(2);
                }
            }

            input {
                display: none;

                &:checked + div {
                    border-color: $color-accent;
                    color: $color-accent;
                    background-color: rgba($color-accent, .1);
                }
            }
        }
    }

    @media (max-width: 575.98px) {
        margin-left: tr(-4);
        margin-right: tr(-4);
        
        > * {
            padding-left: tr(4);
            padding-right: tr(4);
            margin-bottom: tr(8);

            button {

                i {
                    margin-right: tr(4);
                    width: tr(16);
                    height: tr(16);
                    border-radius: 50%;
                    background-color: $color-secondary;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: tr(8);
                }

                span {
                    font-size: tr(10);
                }
            }
        }
    }
}

.input-highlight {
    position: relative;

    .input {
        text-align: right;
        font-weight: bold;
        color: $color-accent;
        font-size: 1.125rem;
    }

    ._icon {
        position: absolute;
        font-weight: bold;
        color: $color-accent;
        left: 1rem;
        transform: translateY(37%);
        font-size: 1.125rem;
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(black, .5);
        visibility: hidden;
        opacity: 0;
        transition: all 0s;
    }

    &__hide {
        border-radius: $rounded-small;
        position: absolute;
        width: 100%;
        margin-top: -1rem;
        background-color: white;
        display: none;
        padding: 1rem;

        label {
            margin-bottom: 0.5rem;
            // font-size: 0.875rem;
            color: $color-base2;

            @media (max-width: 767.98px) {
                font-size: 0.875rem;
            }
        }

        .row {
            --bs-gutter-x: .5rem;
            --bs-gutter-y: .5rem;
        }

        .nom-opt-item {
            width: 100%;
            color: $color-base2;
            border: 1px solid $color-gray;
            border-radius: $rounded-small;
            outline: none !important;
            font-weight: bold;
            padding: 1rem;
            background-color: rgba($color-gray, .2);

            @media (max-width: 767.98px) {
                padding: .75rem .25rem;
                font-size: 0.75rem;
            }

            &._selected {
                background-color: $color-accent;
                border-color: $color-accent;
                color: white;
            }
        }
    }

    &.-focused {
        z-index: 2000;

        .input-pack__label {
            color: white;
        }

        .input-highlight {
            &__overlay {
                visibility: visible;
                opacity: 1;
                transition: all .3s ease-out;
            }

            &__hide {
                display: block;
            }
        }
    }
}