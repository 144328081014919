.Home {
    @import 'components/header';
    @import 'components/selected-campaign';
    @import 'components/selected-program';
    @import 'components/selected-kajian';
    @import 'components/selected-update';
    @import 'components/mitra';
    @import 'components/group';
    @import 'components/cta';
    // @import 'components/sc-program-list';

    .sc-header {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        ._wrapper {
            max-width: tr(392);

            &.-lg {
                max-width: tr(588);
            }
        }
    }

}
.tag-category {
    margin-bottom: tr(24);

    .swiper-slide {
        width: tr(184);
    }
}
.list-card-slider {

    .swiper-slide {
        width: 14rem;
        user-select: none;

        @media (max-width: 767.98px) {
            width: 10rem;
        }
    }
}