.selected-update {
    padding: tr(80) 0 tr(56);
    background-color: rgba($color-gray-light, .2);

    .container {
        .sc-header {
            margin-bottom: tr(24);

            @media (max-width: 767.98px) {
                display: block;

                a {
                    margin-top: tr(16);
                }
            }
        }
    }

    .update-lists {
        position: relative;
        overflow: hidden !important;

        @media (max-width: 991.98px) {
            overflow: visible !important;
        }

        .swiper-slide {
            padding: tr(8) 0 tr(24);

            @media (max-width: 991.98px) {
                width: tr(320);
            }

            @media (max-width: 767.98px) {
                width: tr(280);
            }
        }

        .update-pagination {
            justify-content: center;
        }
    }

    @media (max-width:991.98px) {
        padding: tr(54) 0;

        .container {

            &::before,
            &::after {
                content: unset;
            }
        }
    }
    
    @media (max-width:767.98px) {
        padding: tr(40) 0 tr(40);
    }
}