.project-card {
    position: relative;
    border-radius: $rounded-large;
    box-shadow: $shadow;
    background-color: white;
    overflow: hidden;
    display: block;
    color: $color-base;
    backface-visibility: hidden;
    transition: all .2s ease-in-out;

    // @media (max-width: 575.98px) {
    //     &::before {
    //         position: absolute;
    //         display: block;
    //         content: '';
    //         bottom: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //         background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 20.11%, rgba(0, 0, 0, 0) 108.42%);
    //     }
    // }
    
    &:hover {
        text-decoration: none;
        color: $color-base;

        @media (min-width:992px) {
            // box-shadow: $shadow20;
            transform: translateY(tr(-4)) scale(1.001);
            // transform: scale(1.004) translateY(tr(-3));
        }
    }

    &:active {
        @media (min-width:992px) {
            // box-shadow: $shadow10;
            // transform: scale(1) translateY(0);
            transition: all .1s ease-in-out;
        }
    }

    &__img {
        aspect-ratio: 8 / 3;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__description {
        background-color: white;
        border-radius: $rounded-small;
        padding: tr(16);
        position: relative;
        top: -1rem;
        margin: 0 0.75rem;

        // @media (max-width: 575.98px) {
        //     padding: 0;
        //     background-color: transparent;
        //     color: white;
        //     bottom: tr(24);
        //     left: tr(24);
        //     width: calc(100% - tr(48));
        // }

        &__title {
            font-family: $font-family2;
            font-size: tr(18);
            font-weight: $fw-bold;
            margin-bottom: tr(16);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

            // @media (max-width: 575.98px) {
            //     margin-bottom: tr(8) !important;
            // }
        }

        &__desc {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 11;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;

            // @media (max-width: 575.98px) {
            //     color: white !important;
            //     margin-bottom: 0 !important;
            //     -webkit-line-clamp: 3;
            // }
        }

        &__link {
            transition: all .2s ease-out;
            
            &:hover {
                opacity: .8;
            }

            // @media (max-width: 575.98px) {
            //     display: none;
            // }
        }

    }

}